import { styled, Grid, Typography, Box, Stack } from "@mui/material";
import { ASSETS } from "../../app/constants";
import { useIntl } from "react-intl";

const WUSLargeScreen = () => {
    const Wrapper = styled('section')({
        margin: 0,
    })
    const intl = useIntl()

    const styles = {
        backgroundImage: {
            backgroundImage: `url(${ASSETS.FAMILY_OWNED})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center 20%',
            width: '60%',
            margin: '0 0 1% auto',
            aspectRatio: '513/337'
        },
        backgroundImage2: {
            backgroundImage: `url(${ASSETS.HOUSE})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'bottom',
            width: '60%',
            margin: '1% 0rem 0rem 0rem',
            aspectRatio: '506/343'
        }
    }

    return (
        <Wrapper>
            <Grid container spacing={8} padding={9}>
                <Grid item xs={6}>
                    <Box height='100%'>
                        <Box sx={styles.backgroundImage} />
                        <Box sx={styles.backgroundImage2} />
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{ display: 'grid', placeItems: 'center' }}>
                    <Stack>
                        <Typography variant="h5" color="primary" fontWeight='bold'>
                            {intl.formatMessage({ id: 'aboutUsPage.aboutUsSection.eyebrow' })}
                        </Typography>
                        <Typography variant="h3" color="initial" lineHeight='8rem'>
                            {intl.formatMessage({ id: 'aboutUsPage.aboutUsSection.title' })}
                        </Typography>
                        <Typography fontSize='1rem' lineHeight='2.5rem' color="initial">
                            {intl.formatMessage({ id: 'aboutUsPage.aboutUsSection.text' })}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Wrapper>
    );
}

export default WUSLargeScreen;