import { Step, Stepper, useMediaQuery, Theme, Icon, Box } from "@mui/material";
import { ASSETS } from "../../app/constants";
import WorkProcessCard, { WorkCardProps } from "./WorkProcessCard";
import SouthIcon from '@mui/icons-material/South';
import EastIcon from '@mui/icons-material/East';
import { useIntl } from "react-intl";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const StepConnector = () => {
    const isScreenSmall = useMediaQuery(((theme: Theme) => theme.breakpoints.up('md')));
    return (
        <>
            {isScreenSmall ?
                <EastIcon fontSize="large" color='primary' sx={{ margin: 'auto 0', width: '8rem' }} />
                :
                <SouthIcon fontSize="large" color='primary' sx={{ margin: '0 auto', height: '5rem' }} />
            }
        </>
    )
}

const WorkProcessSection = () => {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0.8,
    });

    const [scrolledTo, setScrolledTo] = useState<number>(0);
    const [isVisible, setIsVisible] = useState<boolean>(false)

    // useEffect(() => {
    //     const ANIMATION_SCROLL_LIMIT = 100;
    //     let isScrollDown = true
    //     if (scrolledTo > 5)
    //         isScrollDown = false
    //     let num = scrolledTo;
    //     const handleScroll = (e: any) => {
    //         e.preventDefault();
    //         if (isVisible) {
    //             isScrollDown ? num++ : num--;
    //             if (num === ANIMATION_SCROLL_LIMIT || num < 1)
    //                 setIsVisible(false)
    //             setScrolledTo(num)
    //         }
    //     };

    //     isVisible && window.addEventListener('wheel', handleScroll, { passive: false });

    //     return () => {
    //         window.removeEventListener('wheel', handleScroll);
    //     };
    // }, [isVisible])

    useEffect(() => {
        setIsVisible(inView)
    }, [inView])

    const intl = useIntl();
    const isScreenSmall = useMediaQuery(((theme: Theme) => theme.breakpoints.up('md')));
    const steps: any[] = [
        {
            image: ASSETS.PHONE_ICON,
            title: intl.formatMessage({ id: 'homePage.workProcessSection.one.title' }),
            subtitle: intl.formatMessage({ id: 'homePage.workProcessSection.one.subtitle' }),
            text: intl.formatMessage({ id: 'homePage.workProcessSection.one.text' }),
            active: true,
            path: '/contact'
        },
        {
            image: ASSETS.DESIGN_ICON,
            title: intl.formatMessage({ id: 'homePage.workProcessSection.two.title' }),
            subtitle: intl.formatMessage({ id: 'homePage.workProcessSection.two.subtitle' }),
            text: intl.formatMessage({ id: 'homePage.workProcessSection.two.text' }),
            active: false,
            path: '/projects'
        },
        {
            image: ASSETS.PROJECT_ICON,
            title: intl.formatMessage({ id: 'homePage.workProcessSection.three.title' }),
            subtitle: intl.formatMessage({ id: 'homePage.workProcessSection.three.subtitle' }),
            text: intl.formatMessage({ id: 'homePage.workProcessSection.three.text' }),
            active: false,
            path: '/projects'
        },
    ]

    return (
        <Stepper ref={ref} connector={<StepConnector />} orientation={isScreenSmall ? 'horizontal' : 'vertical'}
            activeStep={0} sx={{ width: '100%', height: '100%', alignItems: 'stretch', justifyContent: 'flex-start' }}>
            {steps.map((step, index) => (
                <Step key={index} active={true} sx={{ position: 'static', minWidth: { md: '20%', sm: '100%' }, maxWidth: { md: '25%', sm: '100%' } }}>
                    <WorkProcessCard path={step.path} isActive={index * 33 < scrolledTo && scrolledTo < (index + 1) * 33} image={step.image} title={step.title} subtitle={step.subtitle} text={step.text} />
                </Step>
            ))
            }
        </Stepper >
    );
}

export default WorkProcessSection;