import { IconButton, styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Stack } from '@mui/system';
import { ASSETS, MOBILE_APPBAR_HEIGHT } from '../../../app/constants';
import { RENOVATIE_TERTIARY_BLACK_30 } from '../../../styles/theme';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppDispatch } from '../../../store/configureStore';
import { toggleDrawer } from '../../../store/slices/uiSlice';

const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 !important',
});

const MobileHeader = () => {
    const dispatch = useAppDispatch();

    return (
        <AppBar
            position="fixed"
            elevation={0}
            sx={{
                padding: 2,
                backgroundColor: RENOVATIE_TERTIARY_BLACK_30,
                height: MOBILE_APPBAR_HEIGHT,
            }}
        >
            <StyledToolbar>
                <Stack direction="row" justifyContent="space-between" alignItems="center" width='100%' >
                    <IconButton
                        aria-label="open navigation"
                        edge="start"
                        sx={{ mr: 2, display: { lg: 'none' }, color: 'secondary' }}
                        onClick={() => dispatch(toggleDrawer())}
                    >
                        <MenuIcon color='secondary' />
                    </IconButton>
                    <img
                        alt="DVM Renovatie en Gips Logo"
                        style={{ width: 200, height: MOBILE_APPBAR_HEIGHT, paddingRight: 40 }}
                        src={ASSETS.RNV_HEADER_LOGO}
                    />
                </Stack>
            </StyledToolbar>
        </AppBar>
    );
};

export default MobileHeader;
