import { Stack, Typography } from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const LetsTalkText = () => {
    return (
        <Stack direction='column' pl={7} width='100%'>
            <Typography color='secondary' variant='h1' component='h2'>
                <FormattedMessage id='homePage.letsTalkSection.title' />
            </Typography>
            <Typography color='secondary' variant='h4'>
                <FormattedMessage id='homePage.letsTalkSection.text' />
            </Typography>
        </Stack>
    )
}

export default LetsTalkText