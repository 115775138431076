import Box from '@mui/material/Box';
import { styled, Theme } from '@mui/material/styles';
import { RENOVATIE_SECONDARY_NEUTRAL_00, RENOVATIE_SECONDARY_NEUTRAL_10 } from '../../styles/theme';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Header from './header/Header';
import { APPBAR_HEIGHT, DRAWER_WIDTH, MOBILE_APPBAR_HEIGHT } from '../../app/constants';
import Footer from './footer/Footer';
import { Button, Drawer, Stack, Typography, useMediaQuery } from '@mui/material';
import MobileHeader from './header/MobileHeader';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/configureStore';
import { selectIsDrawerOpen, toggleDrawer } from '../../store/slices/uiSlice';
import { useDispatch } from 'react-redux';
import ROUTES from '../../config/routes';
import LanguagePicker from './LanguagePicker';
import { FormattedMessage } from 'react-intl';

const Layout = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'space-between',
    backgroundColor: RENOVATIE_SECONDARY_NEUTRAL_10,
}));

const Main = styled('main')(({ theme }) => ({
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
        marginTop: 0,
    },
    [theme.breakpoints.down('lg')]: {
        marginTop: 0,
    },
    [theme.breakpoints.up('md')]: {
        paddingTop: APPBAR_HEIGHT,
    },
    [theme.breakpoints.down('md')]: {
        paddingTop: MOBILE_APPBAR_HEIGHT,
    },
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    backgroundColor: RENOVATIE_SECONDARY_NEUTRAL_10,
    '*::-webkit-scrollbar': {
        background: 'transparent',
    },
    msOverflowStyle: 'none',
    width: '100%',
}));

const MainLayout = () => {
    const mediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
    const dispatch = useDispatch();
    const location = useLocation();

    const notSelectedItem = { fontWeight: 'normal', color: '#bebec2' }
    const [currentTab, setCurrentTab] = useState<'home' | 'services' | 'contact' | 'about-us' | 'projects'>('home')

    useEffect(() => {

        if (location.pathname.includes(ROUTES.SERVICES)) {
            setCurrentTab('services')
        }

        if (location.pathname.includes(ROUTES.HOME)) {
            setCurrentTab('home')
        }

        if (location.pathname.includes(ROUTES.CONTACT)) {
            setCurrentTab('contact')
        }

        if (location.pathname.includes(ROUTES.PROJECTS)) {
            setCurrentTab('projects')
        }

        if (location.pathname.includes(ROUTES.ABOUT_US)) {
            setCurrentTab('about-us')
        }
    }, [location.pathname])

    return (
        <>
            <Layout>
                {mediumScreen ? <Header /> : <MobileHeader />}
                <Box sx={{ display: 'flex', backgroundColor: RENOVATIE_SECONDARY_NEUTRAL_00, minHeight: '100vH', minWidth: '100%' }}>
                    <Box>
                        <Drawer
                            variant="temporary"
                            open={isDrawerOpen}
                            onClose={() => dispatch(toggleDrawer())}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', md: 'none' },

                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
                            }}>
                            <Stack direction='column' height='100%' justifyContent='space-between'>

                                <Stack direction='column'>
                                    <Button color='tertiary' sx={{ justifyContent: 'flex-end' }} variant='text' onClick={() => dispatch(toggleDrawer())}> x </Button>
                                    <Button variant='text' color='tertiary' sx={{ "&:hover": { backgroundColor: "transparent" }, ...(currentTab !== 'home' ? notSelectedItem : {}) }} disableRipple component={Link} to={ROUTES.HOME}>
                                        <FormattedMessage id='header.b1' />
                                    </Button>
                                    <Button variant='text' color='tertiary' sx={{ "&:hover": { backgroundColor: "transparent" }, ...(currentTab !== 'services' ? notSelectedItem : {}) }} disableRipple component={Link} to={ROUTES.SERVICES}>
                                        <FormattedMessage id='header.b2' />
                                    </Button>
                                    <Button variant='text' color='tertiary' sx={{ "&:hover": { backgroundColor: "transparent" }, ...(currentTab !== 'about-us' ? notSelectedItem : {}) }} disableRipple component={Link} to={ROUTES.ABOUT_US}>
                                        <FormattedMessage id='header.b3' />
                                    </Button>
                                    <Button variant='text' color='tertiary' sx={{ "&:hover": { backgroundColor: "transparent" }, ...(currentTab !== 'projects' ? notSelectedItem : {}) }} disableRipple component={Link} to={ROUTES.PROJECTS}>
                                        <FormattedMessage id='header.b4' />
                                    </Button>
                                </Stack>
                                <LanguagePicker color='black' />

                            </Stack>
                        </Drawer>
                    </Box>
                    <Main>
                        <Outlet />
                    </Main>
                </Box>
                <Footer />
            </Layout>

        </>
    )
};

export default MainLayout;
