import { Box, Grid, Stack, Typography } from "@mui/material";
import FooterContactOptions from "../../../components/common/FooterContactOptions";
import { RENOVATIE_PRIMARY_BLUE } from "../../../styles/theme";
import { ASSETS, FOOTER_BACKGROUND_HEIGHT, FULL_FOOTER_HEIGHT } from "../../../app/constants";
import FollowUsNavigation from "./FollowUsNavigation";
import { useIntl } from "react-intl";
import { useScroll, useTransform } from "framer-motion";
import CallToRequestQuote from "../../../components/common/CallToRequestQuote";

const DesktopFooter = () => {

    const styles = {
        imageGrid: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            backgroundImage: `url(${ASSETS.FOOTER_BACKGROUND})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover 70%',
            backgroundPosition: 'bottom',
        }
    }
    const intl = useIntl()
    const footerRowsDefinitions = [{
        title: intl.formatMessage({ id: 'footer.t1' }),
        routeTo: [
            {
                label: intl.formatMessage({ id: 'footer.fr11' }),
                routeTo: '/about-us'
            },
            {
                label: intl.formatMessage({ id: 'footer.fr12' }),
                routeTo: '/projects'
            },
            {
                label: intl.formatMessage({ id: 'footer.fr13' }),
                routeTo: '/get-quote'
            },
            {
                label: intl.formatMessage({ id: 'footer.fr14' }),
                routeTo: '/contact'
            }
        ]
    }, {
        title: intl.formatMessage({ id: 'footer.t2' }),
        routeTo: [
            {
                label: intl.formatMessage({ id: 'footer.fr21' }),
                routeTo: '/about-us'
            },
            {
                label: intl.formatMessage({ id: 'footer.fr22' }),
                routeTo: '/projects'
            },
            {
                label: intl.formatMessage({ id: 'footer.fr23' }),
                routeTo: '/get-quote'
            },
            {
                label: intl.formatMessage({ id: 'footer.fr24' }),
                routeTo: '/contact'
            },
            {
                label: intl.formatMessage({ id: 'footer.fr25' }),
                routeTo: '/contact'
            },
            {
                label: intl.formatMessage({ id: 'footer.fr26' }),
                routeTo: '/contact'
            },
            {
                label: intl.formatMessage({ id: 'footer.fr27' }),
                routeTo: '/contact'
            },
            {
                label: intl.formatMessage({ id: 'footer.fr28' }),
                routeTo: '/contact'
            },
            {
                label: intl.formatMessage({ id: 'footer.fr29' }),
                routeTo: '/contact'
            },
        ]
    }]

    const { scrollYProgress } = useScroll()
    const y = useTransform(scrollYProgress, [0, 1], ["-80%", "20%"])

    return (
        <Grid container height={FULL_FOOTER_HEIGHT} sx={styles.imageGrid} alignItems='center' justifyContent='flex-start' position='relative'>
            <Grid item height={FOOTER_BACKGROUND_HEIGHT} xs={12} md={6.3} zIndex={1}>
                <Stack direction='column' pt={10} pl={20} pb={18} pr={18} spacing={4}>
                    <Typography color='secondary' fontWeight={900} variant='h3' pb={2}>{intl.formatMessage({ id: 'footer.contact' })}</Typography>
                    <FooterContactOptions spacing={3} />
                </Stack>
            </Grid>
            <Grid item xs={12} md={5.7} height={FULL_FOOTER_HEIGHT} justifyContent='center' alignItems='center' zIndex={1}>
                <Box height='100%' width='90%' padding={8} sx={{ backgroundColor: RENOVATIE_PRIMARY_BLUE }}>
                    <Stack alignItems='center' justifyContent='center' spacing={8}>
                        <img alt='footer-logo' height={102} width={271} src={ASSETS.RNV_FOOTER_LOGO} />
                        <Stack direction='column' alignItems='center' width='100%' spacing={7}>
                            <CallToRequestQuote />
                            <FollowUsNavigation />
                        </Stack>
                    </Stack>
                </Box>
            </Grid>
        </Grid >
    );
}

export default DesktopFooter;