import { Box, Divider, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

export interface StatisticsCardProps {
    icon: string;
    title: string;
    subtitle?: string;
    number: string;
    thousand?: boolean;
    plus?: boolean;
}

const StatisticsCard = ({ icon, number, title, subtitle = '', thousand, plus = false }: StatisticsCardProps) => {
    const ref = useRef(null)
    const isInView = useInView(ref)
    const [firstView, setFirstView] = useState<boolean>(false);
    const isScreenSmall = useMediaQuery(((theme: Theme) => theme.breakpoints.down('sm')));

    useEffect(() => {
        isInView && setFirstView(true)
    }, [isInView])

    const scale = isScreenSmall ? 0.7 : 1;

    return (
        <Stack ref={ref} sx={{ transform: `scale(${scale})`, textAlign: 'center', color: 'white' }} alignItems='center' spacing={1}>
            <Box component='img' src={icon} alt={title} width='7rem' height='auto' />
            <Typography fontSize='7rem' lineHeight='8rem'>
                {firstView &&
                    <CountUp end={Number(number)} duration={5} />
                }
                {`${thousand ? 'k' : ''}`}
                {`${plus ? '+' : ''}`}
            </Typography>
            <Typography fontSize='1.5rem' lineHeight='1.5rem' width={350}>
                {title}
            </Typography>
            <Typography variant="subtitle2">
                {subtitle}
            </Typography>
            <Divider variant="middle" orientation="horizontal" color='secondary.main' sx={{ border: '1px solid white', width: '5rem', marginTop: '1rem' }} />
        </Stack>
    );
}

export default StatisticsCard;