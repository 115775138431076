import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { forwardRef } from 'react'

interface VerticalSectionContainerProps {
    eyebrowText: string;
    title: string;
    titlePosition: 'top' | 'bottom';
    backgroundColor: string;
    children: any;
}

const VerticalSectionContainer = forwardRef<HTMLDivElement, VerticalSectionContainerProps>(
    ({ eyebrowText, title, titlePosition, backgroundColor, children }, ref) => {
        return (
            <Stack ref={ref} direction={titlePosition === 'top' ? 'column' : 'column-reverse'} justifyContent='center' alignItems='center' width='100%' sx={{ backgroundColor: backgroundColor }} spacing={5} paddingTop={10} paddingBottom={10}>
                <Stack direction={titlePosition === 'top' ? 'column' : 'column-reverse'} spacing={1}>
                    <Typography variant='overline' color='primary'>
                        {eyebrowText}
                    </Typography>
                    <Typography variant='h3' color='black'>
                        {title}
                    </Typography>
                </Stack>
                <Box width='lg' height='fit-content' >
                    {children}
                </Box>
            </Stack>
        )
    })

export default VerticalSectionContainer