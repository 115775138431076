import { Box, Button, Grid, IconButton, styled, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Stack } from '@mui/system';
import { APPBAR_HEIGHT, ASSETS, BOTTOM_APPBAR_HEIGHT, TOP_APPBAR_HEIGHT } from '../../../app/constants';
import { RENOVATIE_PRIMARY_BLUE, RENOVATIE_SECONDARY_NEUTRAL_10, RENOVATIE_TERTIARY_BLACK_30 } from '../../../styles/theme';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PinterestIcon from '@mui/icons-material/Pinterest';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguagePicker from '../LanguagePicker';
import { Link, useLocation } from 'react-router-dom';
import ROUTES from '../../../config/routes';

import { useEffect, useState } from 'react';
import ServicesMenu from './ServicesMenu';
import { FormattedMessage } from 'react-intl';
import { loadServices, selectServices } from '../../../store/slices/contentfulSlice';
import { useAppDispatch, useAppSelector } from '../../../store/configureStore';
import { selectCurrentLanguage } from '../../../store/slices/uiSlice';

const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 !important',
});

const Header = () => {
    const theme = useTheme();
    const location = useLocation();
    const locale = useAppSelector(selectCurrentLanguage);
    const services = useAppSelector(selectServices);
    const dispatch = useAppDispatch();
    const mediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        dispatch(loadServices(locale))
    }, [locale])
    const serviceOptions = services?.map((service) => service?.title)

    const notSelectedItem = { fontWeight: 'normal', color: '#bebec2' }
    const [currentTab, setCurrentTab] = useState<'home' | 'services' | 'contact' | 'about-us' | 'projects'>('home')

    useEffect(() => {

        if (location.pathname.includes(ROUTES.SERVICES)) {
            setCurrentTab('services')
        }

        if (location.pathname.includes(ROUTES.HOME)) {
            setCurrentTab('home')
        }

        if (location.pathname.includes(ROUTES.CONTACT)) {
            setCurrentTab('contact')
        }

        if (location.pathname.includes(ROUTES.PROJECTS)) {
            setCurrentTab('projects')
        }

        if (location.pathname.includes(ROUTES.ABOUT_US)) {
            setCurrentTab('about-us')
        }
    }, [location.pathname])

    return (
        <AppBar elevation={0} position="fixed" sx={{ backgroundColor: RENOVATIE_TERTIARY_BLACK_30, height: APPBAR_HEIGHT }}>
            <StyledToolbar>
                <Grid container height="100%">
                    <Grid item xs={12} height={TOP_APPBAR_HEIGHT} sx={{ backgroundColor: RENOVATIE_PRIMARY_BLUE }} display='flex' alignItems='center' justifyContent='center'>
                        <Stack direction="row" spacing={2} sx={{ maxWidth: '80%' }} width="100%" justifyContent='space-between' alignItems='center'>
                            <Stack direction='row' spacing={0}>
                                <Link to={'https://pin.it/1raPw5t'} target="_blank" rel="noopener noreferrer">
                                    <IconButton >
                                        <PinterestIcon color='secondary' fontSize='small' />
                                    </IconButton>
                                </Link>

                                <Link to='https://www.facebook.com/people/DVM-Renovatie-en-Gips/100063623311372/' target="_blank" rel="noopener noreferrer">
                                    <IconButton >
                                        <FacebookIcon color='secondary' fontSize='small' />
                                    </IconButton>
                                </Link>

                                <Link to={'https://www.linkedin.com/in/dvm-renovatie-en-gips-5a4342263/'} target="_blank" rel="noopener noreferrer">
                                    <IconButton >
                                        <LinkedInIcon color='secondary' fontSize='small' />
                                    </IconButton>
                                </Link>

                                <Link to='https://www.instagram.com/dvm_renovatie?igsh=MWxmdmpiMXdtcTc1dA==' target="_blank" rel="noopener noreferrer">
                                    <IconButton >
                                        <InstagramIcon color='secondary' fontSize='small' />
                                    </IconButton>
                                </Link>
                            </Stack>
                            <Typography variant='body2'>
                                <FormattedMessage id='header.above' />
                            </Typography>
                            <LanguagePicker color='white' />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} height={BOTTOM_APPBAR_HEIGHT} width='100%' alignItems='center' justifyContent='center' display='flex'>
                        <Stack direction='row' sx={{ maxWidth: '80%' }} width='100%' justifyContent='space-between' alignItems='center'>
                            <Link to="/home">
                                <img src={ASSETS.RNV_HEADER_LOGO} alt='logo' height={60} />
                            </Link>
                            <Stack direction='row' justifyContent='center' alignItems='center'>
                                <Button variant='text' color='secondary' sx={{ "&:hover": { backgroundColor: "transparent" }, ...(currentTab !== 'home' ? notSelectedItem : {}) }} disableRipple component={Link} to={ROUTES.HOME}>
                                    <FormattedMessage id='header.b1' />
                                </Button>
                                <Stack direction='column'>

                                    <Stack direction='row' justifyContent='center' alignItems='center' spacing={-5}>
                                        <Button variant='text' color='secondary' sx={{ "&:hover": { backgroundColor: "transparent" }, ...(currentTab !== 'services' ? notSelectedItem : {}) }} disableRipple component={Link} to={ROUTES.SERVICES}>
                                            <FormattedMessage id='header.b2' />
                                        </Button>
                                        <IconButton onClick={handleClick}>
                                            <KeyboardArrowDownIcon fontSize='small' color='secondary' />
                                        </IconButton>
                                    </Stack>
                                    <ServicesMenu serviceOptions={serviceOptions ?? []} anchorEl={anchorEl} open={open} onClose={handleClose} />
                                </Stack>
                                <Button variant='text' color='secondary' sx={{ "&:hover": { backgroundColor: "transparent" }, ...(currentTab !== 'about-us' ? notSelectedItem : {}) }} disableRipple component={Link} to={ROUTES.ABOUT_US}>
                                    <FormattedMessage id='header.b3' />
                                </Button>
                                <Button variant='text' color='secondary' sx={{ "&:hover": { backgroundColor: "transparent" }, ...(currentTab !== 'projects' ? notSelectedItem : {}) }} disableRipple component={Link} to={ROUTES.PROJECTS}>
                                    <FormattedMessage id='header.b4' />
                                </Button>
                            </Stack>
                            {mediumScreen && <Button variant='contained' color='secondary' disableRipple component={Link} to={ROUTES.CONTACT}>
                                <FormattedMessage id='header.contact' />
                            </Button>}
                        </Stack>
                    </Grid>
                </Grid>
            </StyledToolbar>
        </AppBar >
    );
};

export default Header;
