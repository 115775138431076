import { ENV_VARS } from '../config/environmentVariables';

import Features, { IContentfulService } from './features';

interface RenovatieArguments {
    contentfulApiKey: string;
}

class renovatie {
    _baseURL: string;
    _headers: any;

    contentful: IContentfulService;

    constructor(args?: RenovatieArguments) {
        const apiEndpoint = ENV_VARS.CONTENTFUL_CDN_ENDPOINT;
        const apiKey = ENV_VARS.CONTENTFUL_CDN_KEY

        this._baseURL = apiEndpoint;
        this._headers = {
            'Content-Type': 'application/json',
        };

        if (args?.contentfulApiKey !== undefined) this.withAuth(args.contentfulApiKey)
        else this.withAuth(apiKey);

        this.contentful = new Features.ContentfulService(this);
    }

    setHeader(key: string, value: string) {
        this._headers[key] = value;
        return this;
    }

    withAuth(token: string) {
        this._headers['Authorization'] = `Bearer ${token}`;
        return this;
    }

    call({ payload }: any) {
        return fetch(this._baseURL, {
            method: 'POST',
            headers: { ...this._headers },
            body: payload,
        })
            .then((res) => res.json())
            .catch((err) => console.log(err));
    }
}

export default renovatie;
