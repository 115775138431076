import { Box, Grid, Pagination, Theme, useMediaQuery } from '@mui/material'
import React, { useRef, useState } from 'react'
import ProjectPostCard from './ProjectPostCard'
import { selectPostNumber, selectPostNumberBig, selectPostNumberSmall } from '../../store/slices/contentfulSlice';
import { useAppSelector } from '../../store/configureStore';

interface ProjectPostsSectionProps {
    blogPosts: any[]; //TODO @Rokva @G Use correct type
    fetch: any;
    currentPage: number;
    bigsmall: 'big' | 'small';
    onPageChange: (page: number) => void;
    titleRef: React.RefObject<HTMLElement>;
}

const ProjectPostsSection = ({ blogPosts, currentPage, fetch, bigsmall, onPageChange, titleRef }: ProjectPostsSectionProps) => {
    const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const postsPerPage = 10;
    const postNumberBig = useAppSelector(selectPostNumberBig);
    const postNumberSmall = useAppSelector(selectPostNumberSmall);
    const postNumber = bigsmall === 'big' ? postNumberBig : postNumberSmall

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = blogPosts;


    const paginate = (pageNumber: number) => {
        fetch(pageNumber);
        onPageChange(pageNumber);

        if (titleRef.current) {
            const yOffsetBigScreenPercentage = 5;
            const yOffsetSmallScreenPercentage = 10;
            const yOffset = smallScreen
                ? titleRef.current.getBoundingClientRect().height * (yOffsetSmallScreenPercentage / 100)
                : titleRef.current.getBoundingClientRect().height * (yOffsetBigScreenPercentage / 100);

            const y = titleRef.current.getBoundingClientRect().top + window.scrollY + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    const isLessThan3Posts = currentPosts?.length <= 3;

    return (
        <>
            <div id={bigsmall}></div>
            <Box display='flex' justifyContent='center' alignItems='center'>
                <Grid container gap={4} direction='row' alignItems='center' width='90%' justifyContent='center'>
                    {currentPosts?.map((post, i) => (
                        <Grid key={i} pt={2} item lg={isLessThan3Posts ? 'auto' : 2} md={isLessThan3Posts ? 'auto' : 3} sm={isLessThan3Posts ? 'auto' : 4} xs={isLessThan3Posts ? 'auto' : 5.5} marginTop={i % 2 === 0 ? 0 : 25}>
                            <ProjectPostCard
                                img={post.photo1.image.url}
                                title={post.title}
                                routeTo={`/blog/${post.slug}`}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box >
            {
                postNumber && (
                    <Box sx={{ width: '100%', display: 'grid', placeItems: 'center' }} mt={8}>
                        <Pagination
                            sx={{ margin: '0 auto' }}
                            count={Math.ceil(postNumber / postsPerPage)}
                            color='primary'
                            page={currentPage}
                            onChange={(event: any, value: number) => {

                                paginate(value)
                            }}
                        />
                    </Box>
                )
            }
        </>
    )
}

export default ProjectPostsSection