
import HeroSection from '../components/homePage/HeroSection';
import ServicesCarouselSection from '../components/homePage/carousel/ServicesCarouselSection';
import VerticalSectionContainer from '../components/common/VerticalSectionContainer';
import { RENOVATIE_SECONDARY_NEUTRAL_10 } from '../styles/theme';
import BadgesSection from '../components/common/BadgesSection';
import LetsTalkSection from '../components/homePage/letsTalk/LetsTalkSection';
import WorkProcessSection from '../components/homePage/WorkProcessSection';
import StatisticsSection from '../components/common/StatisticsSection';
import TestimonialsSection from '../components/homePage/testimonials/TestimonialsSection';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import HorizontalScroll from '../components/homePage/carousel/HorizontalScroll';
import ServiceDialog from '../components/servicesPage/ServiceDialog';
import { useEffect, useState } from 'react';
import { ASSETS } from '../app/constants';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { loadServices, selectServices } from '../store/slices/contentfulSlice';
import { selectCurrentLanguage } from '../store/slices/uiSlice';

const HomePage = () => {
    const intl = useIntl();
    const [isOpenServiceDialog, setOpenServiceDialog] = useState<boolean>(false)
    const [currentServiceIndex, setCurrentServiceIndex] = useState<number | null>(null)
    const services = useAppSelector(selectServices);
    const locale = useAppSelector(selectCurrentLanguage);
    const dispatch = useAppDispatch();
    const [galleryImages, setGalleryImages] = useState<any[]>([]);
    useEffect(() => {
        dispatch(loadServices(locale))
    }, [locale])

    useEffect(() => {
        if (currentServiceIndex != null) {
            const newGalleryImages = servicesData?.[currentServiceIndex]?.imageGalleryCollection?.items?.map((img: any) => ({
                thumbnail: img.url.includes('videos.ctfassets') ? 'https://img.icons8.com/dusk/64/video.png' : img.url,
                original: img.url,
                ...(img.url.includes('videos.ctfassets') && { video: img.url }) //TODO remove workaround (change ctf model)

            }));
            setGalleryImages(newGalleryImages)
        }
    }, [currentServiceIndex])

    const servicesData = services ?? [];

    const handleOpenServiceDialog = (index: number) => {
        setCurrentServiceIndex(index);
        setOpenServiceDialog(true);
    }

    const handleCloseServiceDialog = () => {
        setOpenServiceDialog(false)
        setTimeout(function () {
            setCurrentServiceIndex(null);
        }, 500);
    }
    return (
        <>
            <Helmet>
                <title>DVM Renovatie Home</title>
                <meta name="keywords" content="Renovation, Renovatie, Home, Drywall, Interior, Decoration, Interieur, Decoratie, Floor, Painting" />
                <meta name="description" content="Family owned professional and affordable renovation business, based in Netherlands. Professioneel en betaalbaar renovatiebedrijf in familiebezit, gevestigd in Nederland." />
            </Helmet>
            {/* <ServiceDialog open={isOpenServiceDialog} image={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.image : ASSETS.HERO_IMAGE} title={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.title : 'Placeholder title'} text={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.text : 'Placeholder text'} onClose={handleCloseServiceDialog} /> */}
            <ServiceDialog cardTitle={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.cardTitle : 'Placeholder text'} images={galleryImages} title4={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.title4 : 'Placeholder text'} text4={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.text4 : 'Placeholder text'} image4={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.image4 : 'Placeholder text'} image3={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.image3 : 'Placeholder text'} image2={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.image2 : 'Placeholder text'} title3={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.title3 : 'Placeholder text'} title2={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.title2 : 'Placeholder text'} text3={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.text3 : 'Placeholder text'} text2={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.text2 : 'Placeholder text'} open={isOpenServiceDialog} image={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.image : ASSETS.HERO_IMAGE} title={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.title : 'Placeholder title'} text={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.text : 'Placeholder text'} onClose={handleCloseServiceDialog} />

            <HeroSection title={intl.formatMessage({ id: 'homePage.heroSection.title' })} subtitle={intl.formatMessage({ id: 'homePage.heroSection.subtitle' })} showContactInfo={false} />
            <VerticalSectionContainer titlePosition='top' eyebrowText={intl.formatMessage({ id: 'homePage.verticalSectionContainerServices.eyebrow' })} title={intl.formatMessage({ id: 'homePage.verticalSectionContainerServices.title' })} backgroundColor={RENOVATIE_SECONDARY_NEUTRAL_10}>
                <></>
            </VerticalSectionContainer>
            <HorizontalScroll handleOpen={handleOpenServiceDialog} />
            <BadgesSection />
            <VerticalSectionContainer titlePosition='top' eyebrowText={intl.formatMessage({ id: 'homePage.verticalSectionContainerWorkProcess.eyebrow' })} title={intl.formatMessage({ id: 'homePage.verticalSectionContainerWorkProcess.title' })} backgroundColor={RENOVATIE_SECONDARY_NEUTRAL_10}>
                <Box maxWidth='lg'>
                    <WorkProcessSection />
                </Box>
            </VerticalSectionContainer>
            <StatisticsSection />
            <LetsTalkSection />
            <VerticalSectionContainer titlePosition='top' eyebrowText={intl.formatMessage({ id: 'homePage.verticalSectionContainerTestimonials.eyebrow' })} title={intl.formatMessage({ id: 'homePage.verticalSectionContainerTestimonials.title' })} backgroundColor={RENOVATIE_SECONDARY_NEUTRAL_10}>
                <TestimonialsSection />
            </VerticalSectionContainer>
        </>
    )
}

export default HomePage