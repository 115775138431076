import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Checkboxes } from "mui-rff";
import { Form } from "react-final-form";
import RnvSelectField from "../common/RnvSelectField";
import RnvTextField from "../common/RnvTextField";
import emailjs from '@emailjs/browser';
import { ENV_VARS } from "../../config/environmentVariables";
import REGEX from "../../app/regex";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ASSETS } from "../../app/constants";
import { useEffect, useState } from "react";

interface MessageFormValues {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    streetAddress: string;
    city: string;
    area: string;
    services: any[];
    // attachment: string;
    moreInformation: string;
    privacyPolicy: boolean;
}

const emailRegex = new RegExp(REGEX.EMAIL)
const phoneNumberRegex = new RegExp(REGEX.PHONE_NUMBER)
const MessageForm = () => {
    const intl = useIntl();
    const [openSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);

    const handleSubmit = async (values: MessageFormValues, form: any) => {
        emailjs.sendForm(
            ENV_VARS.EMAILJS_SERVICE_KEY, ENV_VARS.EMAILJS_SEND_MESSAGE_TEMPLATE_ID,
            'form'
            , ENV_VARS.EMAILJS_PUBLIC_KEY
        )
        setOpenSuccessDialog(true);
        form.restart()
    }

    const handleValidate = async (values: MessageFormValues) => {
        let returnObject = {} as any
        if (!values.firstName) {
            returnObject.firstName = 'This field is required!'
        }
        if (!phoneNumberRegex.test(values.phoneNumber)) {
            returnObject.phoneNumber = 'That is not a valid phone number!'
        }
        if (!emailRegex.test(values.email)) {
            returnObject.email = 'That is not a valid email address'
        }
        if (!values.streetAddress) {
            returnObject.streetAddress = 'This field is required!'
        }
        if (!values.city) {
            returnObject.city = 'This field is required!'
        }
        if (!values.area) {
            returnObject.area = 'This field is required!'
        }
        if (!values.services) {
            returnObject.services = 'This field is required!'
        }
        if (!values.privacyPolicy) {
            returnObject.privacyPolicy = 'You have to agree to our privacy policy'
        }
        // if (!values.attachment) {
        //     returnObject.attachment = 'This field is required!'
        // }
        if (!values.moreInformation) {
            returnObject.moreInformation = 'This field is required!'
        }


        return returnObject
    }

    return (
        <Form
            onSubmit={handleSubmit}
            validate={handleValidate}
            render={({ handleSubmit }) => (
                <form id={'form'} onSubmit={handleSubmit} noValidate={true}>
                    <Stack direction='column' spacing={4} alignItems='center'>

                        {openSuccessDialog && <Typography variant="h5" sx={{ color: 'green' }} >{intl.formatMessage(({ id: 'common.successfullMessage' }))}</Typography>}
                        <Stack direction='column' spacing={4}>

                            <Stack direction={{ md: 'column', lg: 'row' }} spacing={4}>
                                <RnvTextField placeholder={intl.formatMessage({ id: 'letsTalkForm.fullName.placeholder' })} label={intl.formatMessage({ id: 'letsTalkForm.fullName.label' })} name='firstName' required={true} />
                            </Stack>
                            <Stack direction={{ md: 'column', lg: 'row' }} spacing={4}>
                                <RnvTextField placeholder={intl.formatMessage({ id: 'letsTalkForm.phoneNumber.placeholder' })} label={intl.formatMessage({ id: 'letsTalkForm.phoneNumber.label' })} name='phoneNumber' required={true} />
                                <RnvTextField placeholder={intl.formatMessage({ id: 'letsTalkForm.email.placeholder' })} label={intl.formatMessage({ id: 'letsTalkForm.email.label' })} name='email' required={true} />
                            </Stack>
                            <Stack direction={{ md: 'column', lg: 'row' }} spacing={4}>
                                <Box width='100%'>
                                    <RnvTextField placeholder={intl.formatMessage({ id: 'letsTalkForm.streetAddress.placeholder' })} label={intl.formatMessage({ id: 'letsTalkForm.streetAddress.label' })} name='streetAddress' required={true} />
                                </Box>
                                <Stack spacing={4} direction={{ md: 'column', lg: 'row' }} width='100%'>
                                    <RnvTextField placeholder={intl.formatMessage({ id: 'letsTalkForm.city.placeholder' })} label={intl.formatMessage({ id: 'letsTalkForm.city.label' })} name='city' required={true} />
                                    <RnvTextField placeholder="ZIP" label="ZIP" name='area' required={true} />
                                </Stack>
                            </Stack>
                            <Stack direction={{ md: 'column', lg: 'row' }} spacing={4}>
                                <RnvSelectField placeholder='' label={intl.formatMessage({ id: 'letsTalkForm.services.title' })} name='services' required={true} multiSelect
                                    data={[
                                        { value: 'Renovation', label: intl.formatMessage({ id: 'letsTalkForm.services.items.renovation' }) },
                                        { value: 'Plaster Work', label: intl.formatMessage({ id: 'letsTalkForm.services.items.plaster_work' }) },
                                        { value: 'Custom Kitchen Molds', label: intl.formatMessage({ id: 'letsTalkForm.services.items.custom_kitchen_molds' }) },
                                        { value: 'Interior and Exterior Decoration', label: intl.formatMessage({ id: 'letsTalkForm.services.items.iae_decor' }) },
                                        { value: 'Modern Drywall and ceilings', label: intl.formatMessage({ id: 'letsTalkForm.services.items.drywall' }) },
                                        { value: 'Floors laminate and carpets', label: intl.formatMessage({ id: 'letsTalkForm.services.items.floors' }) },
                                        { value: 'Painting work', label: intl.formatMessage({ id: 'letsTalkForm.services.items.painting' }) },
                                        { value: 'Innovative Advices', label: intl.formatMessage({ id: 'letsTalkForm.services.items.advice' }) },
                                    ]} />
                                {/* <RnvTextField placeholder="TODO: file upload" label="Have any photos? Attach here" name='attachment' required={true}></RnvTextField> */}
                            </Stack>
                            <RnvTextField placeholder={intl.formatMessage({ id: 'letsTalkForm.relevantInfo.placeholder' })} label={intl.formatMessage({ id: 'letsTalkForm.relevantInfo.label' })} name='moreInformation' required={true} multiline={true} rows={4} ></RnvTextField>
                            <Checkboxes
                                name="privacyPolicy"
                                size="small"
                                style={{
                                    fontSize: '0.8rem'
                                }}
                                data={{
                                    value: false,
                                    label: <Typography variant='caption' color='inherit'>{intl.formatMessage({ id: 'letsTalkForm.pp_intro' })} <Typography component='a' href={ASSETS.PRIVACY_POLICY} target="_blank" sx={{ textDecoration: 'none' }} variant='caption' color='primary'>{intl.formatMessage({ id: 'letsTalkForm.pp_label' })}</Typography></Typography>,
                                }}
                            />
                            <br />
                        </Stack>

                        <Button type='submit' variant="contained" sx={{ maxWidth: { md: '30%', sm: '60%' } }}>
                            <FormattedMessage id="contactPage.form.btnSubmit" />
                        </Button>
                    </Stack>
                </form>
            )}
        />
    );
}

export default MessageForm;