import { Stack, Typography } from '@mui/material'
import { TextField } from 'mui-rff'

interface RnvTextFieldProps {
    label: string;
    name: string;
    placeholder: string;
    required?: boolean;
    multiline?: boolean;
    rows?: number;
}

const RnvTextField = ({ label, name, placeholder, required = false, multiline = false, rows = 1, }: RnvTextFieldProps) => {
    return (
        <Stack direction='column' width='100%'>
            <Typography fontSize='1.375rem' fontWeight={400}>{label} <Typography fontSize='1.375rem' color='error' component='span'>{required ? '*' : ''}</Typography></Typography>
            <TextField
                required
                variant='standard'
                sx={{
                    backgroundColor: '#0000000A',
                    mt: 1,
                    padding: 1.5,
                    borderRadius: 3
                }}
                InputProps={{
                    disableUnderline: true,
                }}
                name={name}
                placeholder={placeholder}
                multiline={multiline}
                rows={rows}
                margin="normal"
                fullWidth
            />
        </Stack>
    )
}

export default RnvTextField