import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { ASSETS } from "../../app/constants";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import theme from "../../styles/theme";
import { useIntl } from "react-intl";

const LicensedSegment = () => {
    const styles = {
        backgroundImage: {
            [theme.breakpoints.down('lg')]: {
                backgroundSize: 'contain',
                height: '30rem',
                minHeigh: '18rem'
            },
            display: 'flex',
            justifyContent: 'center',
            backgroundImage: `url(${ASSETS.ROOM_PAINTING})`,
            alignItems: 'space-between',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        },
    }
    const intl = useIntl()

    return (
        <Grid container width={'100%'} marginRight={4}>
            <Grid item xs={12} md={6} sx={{ display: 'grid', placeItems: 'center' }}>
                <Box component='img' src={ASSETS.LICENSED_BADGE} sx={{ margin: 'auto' }} alt="licensed" maxWidth='50%' />
            </Grid>
            <Grid item xs={12} md={6} paddingLeft={5} padding={7.87}>
                <Typography variant="h5" color="primary" fontWeight='bold'>
                    {intl.formatMessage({ id: 'aboutUsPage.licensedSection.eyebrow' })}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '2.3rem',
                        [theme.breakpoints.up('sm')]: {
                            fontSize: '3rem',
                        },
                        fontWeight: 'bold',
                        lineHeight: '4rem',
                        [theme.breakpoints.up('sm')]: {
                            lineHeight: '6rem',
                        }
                    }}>
                    {intl.formatMessage({ id: 'aboutUsPage.licensedSection.title' })}
                </Typography>
                <Typography variant="h4" fontWeight={550} fontSize='1.5rem' color="black" lineHeight='2.5rem'>
                    {intl.formatMessage({ id: 'aboutUsPage.licensedSection.subtitle' })}
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="initial" sx={{ fontSize: '1.2rem' }}>
                                {intl.formatMessage({ id: 'aboutUsPage.licensedSection.listItem1' })}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="initial" sx={{ fontSize: '1.2rem' }}>
                                {intl.formatMessage({ id: 'aboutUsPage.licensedSection.listItem2' })}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="initial" sx={{ fontSize: '1.2rem' }}>
                                {intl.formatMessage({ id: 'aboutUsPage.licensedSection.listItem3' })}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecordIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1" color="initial" sx={{ fontSize: '1.2rem' }}>
                                {intl.formatMessage({ id: 'aboutUsPage.licensedSection.listItem4' })}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </List>
                <Typography variant="h6" color="initial" >
                    {intl.formatMessage({ id: 'aboutUsPage.licensedSection.bottomText' })}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default LicensedSegment;