import { Theme, useMediaQuery } from '@mui/material'
import MobileFooter from './MobileFooter';
import DesktopFooter from './DesktopFooter';


const Footer = () => {

    const isScreenLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

    return (
        <>
            {
                isScreenLarge ?
                    <DesktopFooter /> :
                    <MobileFooter />
            }
        </>
    )
}

export default Footer