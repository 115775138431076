import { Box } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import TestimonialsCarousel from './TestimonialsCarousel'

const TestimonialsSection = () => {
    const intl = useIntl();
    const Data = [
        {
            title: 'Désirée van Deelen',
            description: "Hard werkende mannen. Hebben bij ons een nieuw gipsplaten plafond erin gezet. Werd compleet geverfd opgeleverd. Ze waren op korte termijn beschikbaar. De communicatie is zeer netjes. Ze denken mee in oplossingen.",
            subtitle: intl.formatMessage({ id: 'homePage.testimonialsSection.titles.client' }),
            stars: 4,
        },
        {
            title: 'Dennis',
            subtitle: intl.formatMessage({ id: 'homePage.testimonialsSection.titles.client' }),
            stars: 5,
            description: "Ze hebben een nieuwe tussenwand geplaatst bij ons. Goede kwaliteit, werken hard, nette prijs, hele aardige mensen en betrouwbaar familiebedrijf!"
        },
        {
            title: 'BADGER NV Multi Solutions',
            subtitle: intl.formatMessage({ id: 'homePage.testimonialsSection.titles.client' }),
            stars: 5,
            description: "Toppers!"
        },
        {
            title: 'Verica Murdjevic',
            stars: 5,
            subtitle: intl.formatMessage({ id: 'homePage.testimonialsSection.titles.client' }),
            description: ""
        },
        {
            title: 'Celine de Roos',
            stars: 5,
            subtitle: intl.formatMessage({ id: 'homePage.testimonialsSection.titles.client' }),
            description: "De heren van DVM renovatie en gips hebben bij ons sierlijsten op de muren gemonteerd. We zijn super blij met het resultaat! Ze kwamen netjes hun afspraken na en gingen professioneel te werk. Ik kan iedereen aanraden om dit door hun te laten doen! :)"
        },
        {
            title: 'Frank van Es',
            stars: 5,
            subtitle: intl.formatMessage({ id: 'homePage.testimonialsSection.titles.client' }),
            description: "Goede ervaring met het plaatsen van een muurtje. Goede communicatie vooraf, vriendelijk en meedenkend personeel en werk opgeleverd zoals afgesproken"
        },
    ]
    return (
        <Box display='flex' justifyContent='center' alignItems='center'>
            <TestimonialsCarousel data={Data} />
        </Box>
    )
}

export default TestimonialsSection