
export const PAGE_WIDTH = 1000;
export const APPBAR_HEIGHT = 135;
export const MOBILE_APPBAR_HEIGHT = 100;
export const TOP_APPBAR_HEIGHT = 32;
export const BOTTOM_APPBAR_HEIGHT = 105;
export const DRAWER_WIDTH = 260;

export const FULL_FOOTER_HEIGHT = 650;
export const FOOTER_BACKGROUND_HEIGHT = 531;

export const ASSETS = {
    PRIVACY_POLICY: '/dvm-privacy-policy.pdf',
    RNV_HEADER_LOGO: '/renovatie_logo.svg',
    RNV_FOOTER_LOGO: '/renovatie_logo_footer.svg',
    FOOTER_BACKGROUND: '/footer_background.svg',
    FOOTER_PHONE_ICON: '/phone_icon.svg',
    PROJECT_POST_THUNBNAIL: '/project_post_thumbnail.svg',
    GALLERY_PLACEHOLDER: '/gallery_placeholder.svg',
    HERO_IMAGE: '/hero_image.svg',
    SERVICE_CARD_IMAGE: '/service_card_image.svg',
    MONEY_BACK_BADGE: '/money_back_badge.svg',
    LICENSED_BADGE: '/licensed_badge.svg',
    GUARANTEE_BADGE: '/guarantee_badge.svg',
    LETS_TALK_IMAGE: '/lets_talk_image.svg',
    PHONE_ICON: '/phone_mui_icon.svg',
    DESIGN_ICON: '/design_mui_icon.svg',
    PROJECT_ICON: '/project_mui_icon.svg',
    SMILEY_ICON: '/smiley.svg',
    BRIEFCASE_ICON: '/briefcase.svg',
    ROCKET_ICON: '/rocket.svg',
    WORKER_ICON: '/worker.svg',
    HAPPY_PEOPLE: '/happy_people.svg',
    NICE_HOUSE: '/nice_house.svg',
    ROOM_PAINTING: '/painting.svg',
    WARRANTY_BADGE: '/warranty_badge.svg',
    FAMILY_OWNED: '/family_owned.svg',
    HOUSE: '/house.svg',
    DESIGN: '/design.svg',
    SERVICE_CARDS: {
        ADVICE: `/services/advice.png`,
        KITCHEN: `/services/customKitchen.svg`,
        DRY_CEILING: `/services/dryCeiling.svg`,
        FLOOR_LAMINATE: `/services/laminate.svg`,
        INTERIOR_EXTERIOR_DESIGN: `/services/interior-exterior.svg`,
        PAINTING: `/services/painting.svg`,
        PLASTER_WORKS: `/services/plaster-work.svg`,
        RENOVATION: `/services/renovation.svg`,
    }
}