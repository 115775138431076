import { Box, Stack, Typography, Container } from "@mui/material";
import { Link } from "react-router-dom";

export interface WorkCardProps {
    image: string;
    title: string;
    subtitle: string;
    text: string;
    isActive: boolean;
    path: string;
}

const WorkProcessCard = ({ image, title, subtitle, text, isActive, path }: WorkCardProps) => {
    const styles = {
        container: {
            height: '100%',
            width: '100%',
            margin: '0 auto 0 10px',
            borderRadius: '10px',
            padding: '2rem',
            display: 'flex',
            overflowWrap: 'break-word',
            border: isActive ? '2px solid blue' : '',
            transition: 'all 0.2s ease-in-out',
            cursor: 'pointer',
            '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: 'rgba(21, 7, 171, 0.4) 0px 5px 15px'
            },
            transform: isActive ? 'scale(1.1)' : 'none',
            boxShadow: isActive ? 'rgba(21, 7, 171, 0.4) 0px 5px 15px' : 'none'

        },
        picture: {
            width: '3rem',
            paddingBottom: '1rem'
        }
    }

    return (
        <Link to={path} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Stack bgcolor='secondary.main' sx={styles.container}>
                <Box component={'img'} src={image} alt={title} sx={styles.picture} />
                <Typography variant="h5" >{title}</Typography>
                <Typography variant="h6" color={'primary.main'} sx={{ paddingBottom: '1rem' }}>{subtitle}</Typography>
                <Typography variant="body1" >{text}</Typography>
            </Stack>
        </Link>
    );
}

export default WorkProcessCard;