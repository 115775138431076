import { BlogPostResponse } from '../../../store/slices/contentfulSlice';
import * as contentfulQueries from './contentfulQueries';

class ContentfulService {
	private renovatie: any;

	constructor(renovatie: any) {
		this.renovatie = renovatie;
	}

	async loadBlogPosts(limit: number, skip: number, bigsmall: 'big' | 'small' | null, locale: 'en-US' | 'nl-NL'): Promise<{ response: BlogPostResponse; errors: any }> {
		const payload = contentfulQueries.getBlogPosts(limit, skip, bigsmall, locale);
		const { data, errors } = await this.renovatie.call({ payload });
		return { response: { items: data?.dvmRenovatienCollection.items, total: data?.dvmRenovatienCollection.total }, errors };
	}
	async loadServices(locale: 'en-US' | 'nl-NL'): Promise<{ response: any; errors: any }> {
		const payload = contentfulQueries.getServices(locale);
		const { data, errors } = await this.renovatie.call({ payload });
		const services = data?.serviceCollection?.items?.map((item: any) => {
			item.image = item?.image?.url;
			item.image2 = item?.image2?.url;
			item.image3 = item?.image3?.url;
			item.image4 = item?.image4?.url;

			return item
		})

		return { response: services, errors };
	}

	async loadImages(): Promise<{ images: any[]; errors: any }> {
		const payload = contentfulQueries.getImages();
		const { data, errors } = await this.renovatie.call({ payload });
		return { images: data?.photoCollection?.items, errors };
	}

	async getBlogPostBySlug(slug: string, locale: 'en-US' | 'nl-NL'): Promise<{ blog_post: any, errors: any }> {
		const payload = contentfulQueries.getBlogPostsBySlug(slug, locale);
		const { data, errors } = await this.renovatie.call({ payload });
		return { blog_post: data?.dvmRenovatienCollection?.items, errors };
	}

}

export default ContentfulService;
