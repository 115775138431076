import { Box, Button, Card, CardContent, CardMedia, Paper, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import { FormattedMessage } from 'react-intl';

interface ServiceCardProps {
    title: string;
    description: string;
    image: string;
    fullSize?: boolean;
    onReadMore?: () => void;
    reverse: boolean;
}

const ServiceCard = ({ title, description, image, fullSize = false, onReadMore, reverse }: ServiceCardProps) => {
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Card sx={{ display: 'flex', flexDirection: reverse ? "row" : "row-reverse", padding: 4, width: !fullSize ? { xs: 'min-content', lg: 'fit-content' } : 'auto', height: { xs: 'min-content', lg: 500 }, justifyContent: 'space-between' }} component={Paper} elevation={2} >
            <Stack direction='column' sx={{ alignItems: reverse ? "flex-start" : "flex-end" }}>
                {!largeScreen && <CardMedia
                    component="img"
                    sx={{ width: 200, height: 200 }}
                    image={image}
                    alt="Live from space album cover"
                />}
                <CardContent sx={{
                    flex: '1 0 auto',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    height: 'max-content',
                    whiteSpace: 'pre-line',
                }}>
                    <Stack direction='column' spacing={4}>

                        <Typography variant="h5">
                            {title}
                        </Typography>

                        {largeScreen && <Typography sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                        }} variant="subtitle1" color="text.secondary">
                            {description}
                        </Typography>}
                    </Stack>
                </CardContent>
                <Box>
                    <Button disableFocusRipple variant='contained' color='primary' onClick={onReadMore}>
                        <FormattedMessage id='common.btnReadMore' />
                    </Button>
                </Box>
            </Stack>
            {largeScreen && <CardMedia
                component="img"
                sx={{ width: 400 }}
                image={image}
                alt="Live from space album cover"
            />}
        </Card>
    )
}

export default ServiceCard