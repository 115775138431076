import { SupportedLocaleKeys } from './messages';

interface Navigator {
	userLanguage?: string;
	browserLanguage?: string;

	languages: readonly string[];
	language: string;
}

const navigator: Navigator = window?.navigator;

const determineLocale = (): SupportedLocaleKeys => {
	const SUPPORTED_LOCALES = ['en-US', 'nl-NL'];
	const DEFAULT_LOCALE = 'en-US';

	const currentUserLocale =
		(navigator?.languages?.[0] as SupportedLocaleKeys) ||
		(navigator.language as SupportedLocaleKeys) ||
		(navigator.userLanguage as SupportedLocaleKeys)

	if (SUPPORTED_LOCALES.includes(currentUserLocale)) return currentUserLocale;

	return DEFAULT_LOCALE;
};

export const locale = determineLocale();
