import MessageForm from "../components/contactPage/MessageForm";
import ContactCard from "../components/contactPage/ContactCard";
import { Box, Stack, styled, Typography } from "@mui/material";
import theme from '../styles/theme'
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";

const StyledSection = styled('section')({
    [theme.breakpoints.up('sm')]: {
        padding: '3rem 9.5rem'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '1rem'
    }
})

const ContactPage = () => {

    const styles = {
        container: {
            marginTop: '1rem',
            padding: '3rem 4rem',
            boxShadow: '0px 2px 25px rgba(0, 33, 91, 0.207605)',
            width: '100%'
        }
    }

    return (
        <>
            <Helmet>
                <title>DVM - Contact Page</title>
                <meta name="description" content="Get in touch, send us a message, contact us! Get an estimate for the cost of your project. Neem contact op, stuur ons een bericht, neem contact met ons op! Ontvang een kostenraming voor uw project." />
                <meta name='keywords' content="Contact, Quote, Price, Estimate, Renovation, Contact, Offerte, Prijs, Schatting, Renovatie"></meta>
            </Helmet>
            <StyledSection>
                <Stack spacing={8}>
                    <Box>
                        <Typography variant="h6" color="primary">
                            <FormattedMessage id='contactPage.container.eyebrow' />
                        </Typography >
                        <Typography variant="h3" color="initial">
                            <FormattedMessage id='contactPage.container.title' /></Typography>
                    </Box >
                    <Stack direction={{ md: 'column', lg: 'row-reverse' }} spacing={14}>
                        <ContactCard />
                        <Box sx={styles.container}>
                            <MessageForm />
                        </Box>
                    </Stack>
                </Stack >
            </StyledSection >
        </>
    );

}

export default ContactPage;