interface EnvVar {
    envName: string;
    value: any;
}
const assertEnvVar = ({ envName, value }: EnvVar): void => {
    if (['', null, undefined].includes(value)) {
        const errorMsg = `Environment variable ${envName} is missing!.`;
        throw new Error(errorMsg);
    }
};

const getEnvironmentVariable = (environmentVariableName: string) => {
    const envVar = process.env[environmentVariableName];
    assertEnvVar({ envName: environmentVariableName, value: envVar });
    if (!envVar) throw new Error('Error with environment variables');

    return envVar;
};

interface EnvVars {
    CONTENTFUL_CDN_KEY: string;
    CONTENTFUL_CDN_ENDPOINT: string;
    EMAILJS_SERVICE_KEY: string;
    EMAILJS_SEND_MESSAGE_TEMPLATE_ID: string;
    EMAILJS_LETS_TALK_TEMPLATE_ID: string;
    EMAILJS_PUBLIC_KEY: string;
}
export const ENV_VARS: EnvVars = {
    CONTENTFUL_CDN_KEY: getEnvironmentVariable('REACT_APP_CONTENTFUL_CDN_KEY'),
    CONTENTFUL_CDN_ENDPOINT: getEnvironmentVariable('REACT_APP_CONTENTFUL_CDN_ENDPOINT'),
    EMAILJS_SERVICE_KEY: getEnvironmentVariable('REACT_APP_EMAILJS_SERVICE_KEY'),
    EMAILJS_SEND_MESSAGE_TEMPLATE_ID: getEnvironmentVariable('REACT_APP_EMAILJS_SEND_MESSAGE_TEMPLATE_ID'),
    EMAILJS_LETS_TALK_TEMPLATE_ID: getEnvironmentVariable('REACT_APP_EMAILJS_LETS_TALK_TEMPLATE_ID'),
    EMAILJS_PUBLIC_KEY: getEnvironmentVariable('REACT_APP_EMAILJS_PUBLIC_KEY'),
};
