import * as flat from 'flat';

import messages_nl from './messages_nl';
import messages_en from './messages_en';

export interface ISupportedLangs {
	'en-US': string;
	'nl-NL': string;
}

export type SupportedLocaleKeys = keyof ISupportedLangs;

const keepArrays = {
	safe: true,
};
export const messages = {
	'en-US': flat.flatten(messages_en, keepArrays),
	'nl-NL': flat.flatten(messages_nl, keepArrays),
};
