import * as flat from 'flat';
import { createIntl, createIntlCache } from 'react-intl';

import { messages, SupportedLocaleKeys } from './messages';

const keepArrays = {
	safe: true,
};
const cache = createIntlCache();

let int = createIntl(
	{
		locale: 'nl-NL',
		messages: flat.flatten(messages['en-US'], keepArrays),
	},
	cache
);

export function changeLanguage(lang: SupportedLocaleKeys) {
	const newIntl = createIntl(
		{
			locale: lang,
			messages: flat.flatten(messages[lang], keepArrays),
		},
		cache
	);
	int = newIntl;
}

export const t = (id: string, values?: any) => {
	return int.formatMessage({ id }, values);
};
