import { Box, Stack } from '@mui/material'
import ContactOption from './ContactOption'
import PhoneSharpIcon from '@mui/icons-material/PhoneSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import { useIntl } from 'react-intl';

interface FooterContactOptionsProps {
    spacing: number;
}

const FooterContactOptions = ({ spacing }: FooterContactOptionsProps) => {
    const intl = useIntl()
    return (
        <Stack direction='column' spacing={spacing} justifyContent='flex-start' alignItems='center'>
            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2} width='100%'>
                <ContactOption icon={<PhoneSharpIcon sx={{ border: "solid 2px", borderRadius: 10, height: 45, width: 45, padding: 1.3 }} color='secondary' />} title={'tel:+31 648989364'} anchorTitle={true} subtitle='08:00 am - 07:00 pm' />
            </Stack>
            <Stack sx={{ textDecoration: 'none' }} direction='row' justifyContent='flex-start' alignItems='center' spacing={2} width='100%' component={'a'} href='mailto:info@dvmrenovatie.com'>
                <ContactOption icon={<EmailSharpIcon sx={{ border: "solid 2px", borderRadius: 10, height: 45, width: 45, padding: 1.3 }} color='secondary' />} title={'info@dvmrenovatie.com'} subtitle={intl.formatMessage({ id: 'homePage.heroSection.write' })} />
            </Stack>
            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2} width='100%'>
                <ContactOption icon={<LocationOnSharpIcon sx={{ border: "solid 2px", borderRadius: 10, height: 45, width: 45, padding: 1.3 }} color='secondary' />} title={'Rotterdam'} subtitle={intl.formatMessage({ id: 'homePage.heroSection.visit' })} />
            </Stack>
        </Stack>
    )
}

export default FooterContactOptions