import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { ASSETS } from "../../app/constants";
import { useIntl } from "react-intl";

const WarrantySegment = () => {
    const theme = useTheme()
    const intl = useIntl()
    return (
        <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }} width='100%' justifyContent='center' alignItems='center'>
            <Grid item xs={12} md={6} sx={{ padding: { xs: '0 2rem', md: '0 0 0 7rem' } }}>
                <Stack spacing={2}>
                    <Typography variant="h5" color="primary" fontWeight='bold'>
                        {intl.formatMessage({ id: 'aboutUsPage.warrantySection.eyebrow' })}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '3rem',
                            fontWeight: 'bold',
                            lineHeight: '3.5rem',
                            [theme.breakpoints.up('sm')]: {
                                lineHeight: '6rem',
                            }
                        }}
                    >
                        {intl.formatMessage({ id: 'aboutUsPage.warrantySection.title' })}
                    </Typography>
                    <Typography fontSize={'1.3rem'} marginTop={'5rem'}>
                        {intl.formatMessage({ id: 'aboutUsPage.warrantySection.p1' })}
                    </Typography>
                    <Typography fontSize={'1.3rem'} marginTop={'5rem'}>
                        {intl.formatMessage({ id: 'aboutUsPage.warrantySection.p2' })}
                    </Typography>
                    <Typography fontSize={'1.3rem'} marginTop={'5rem'}>
                        {intl.formatMessage({ id: 'aboutUsPage.warrantySection.p3' })}
                    </Typography>
                    <Typography fontSize={'1.3rem'} marginTop={'5rem'}>
                        {intl.formatMessage({ id: 'aboutUsPage.warrantySection.p4' })}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'grid', placeItems: 'center' }} >
                <Box component='img' src={ASSETS.WARRANTY_BADGE} alt="licensed" maxWidth='50%' />
            </Grid>
        </Grid>
    );
}

export default WarrantySegment;