import { Stack, Typography } from '@mui/material'
import { Select } from 'mui-rff'

interface RnvRnvSelectFieldProps {
    label: string;
    name: string;
    placeholder: string;
    required?: boolean;
    data: { value: any, label: any }[];
    multiSelect?: boolean;
}

const RnvSelectField = ({ label, name, placeholder, required = false, data, multiSelect = false }: RnvRnvSelectFieldProps) => {
    return (
        <Stack direction='column' width='100%'>
            <Typography fontSize='1.375rem' fontWeight={400}>{label} <Typography fontSize='1.375rem' color='error' component='span'>{required ? '*' : ''}</Typography></Typography>
            <Select
                required
                variant='standard'
                sx={{
                    backgroundColor: '#0000000A',
                    mt: 1,
                    padding: 1.5,
                    borderRadius: 3
                }}
                disableUnderline
                data={data}
                name={name}
                placeholder={placeholder}
                fullWidth
                multiple={multiSelect}
            />
        </Stack>
    )
}

export default RnvSelectField