import { Grid, styled, Typography, useTheme } from "@mui/material";
import { ASSETS } from "../../app/constants";
import { useIntl } from "react-intl";

const OurMissionSection = () => {
    const theme = useTheme()
    const intl = useIntl()

    const Wrapper = styled('section')({
        margin: 0,
    })

    const styles = {
        backgroundImage: {
            [theme.breakpoints.down('lg')]: {
                backgroundSize: 'contain',
                height: '30rem',
                minHeigh: '18rem'
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            display: 'flex',
            justifyContent: 'center',
            backgroundImage: `url(${ASSETS.DESIGN})`,
            alignItems: 'space-between',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
    }

    return (
        <Wrapper>
            <Grid container
                sx={{
                    padding: '5rem',
                    [theme.breakpoints.down('sm')]: {
                        padding: '2.5rem',
                    },
                }}
            >
                <Grid item xs={12} sm={6} padding={'0 2rem 0 0'}>
                    <Typography variant="h5" color="primary" fontWeight='bold'>
                        {intl.formatMessage({ id: 'aboutUsPage.ourMissionSection.eyebrow' })}
                    </Typography>
                    <Typography sx={{
                        fontSize: '2.3rem',
                        [theme.breakpoints.up('sm')]: {
                            fontSize: '3rem',
                        },
                        fontWeight: 'bold',
                        lineHeight: '4rem',
                        [theme.breakpoints.up('sm')]: {
                            lineHeight: '6rem',
                        }
                    }}>
                        {intl.formatMessage({ id: 'aboutUsPage.ourMissionSection.title' })}
                    </Typography>
                    <Typography fontWeight={550} fontSize='1rem' color="black" lineHeight='2.5rem'>
                        {intl.formatMessage({ id: 'aboutUsPage.ourMissionSection.text' })}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={styles.backgroundImage} />
            </Grid>
        </Wrapper >
    );
}

export default OurMissionSection;