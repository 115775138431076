import { useMediaQuery, Theme } from "@mui/material";
import WUSLargeScreen from "./WUSLargeScreen";
import WUSSmallScreen from "./WUSSmallScreen";

const WhyUsSection = () => {
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <>
            {
                largeScreen ?
                    <WUSLargeScreen /> : <WUSSmallScreen />
            }
        </>
    );
}

export default WhyUsSection;