import { Box, Grid, Paper } from '@mui/material'
import { ASSETS } from '../../../app/constants'
import React, { useRef } from 'react'
import LetsTalkText from './LetsTalkText'
import LetsTalkForm from './LetsTalkForm'
import { AnimatePresence, motion, useScroll, useTransform } from 'framer-motion'

const LetsTalkSection = () => {
    const parentRef = useRef(document.getElementById('parent'))
    const { scrollYProgress } = useScroll()
    const y = useTransform(scrollYProgress, [0, 1], ["-250%", "40%"])

    return (
        <section style={{ position: 'relative' }}>
            <AnimatePresence>
                <Box sx={{
                    position: 'absolute',
                    zIndex: '0',
                    width: '100%', height: '100%',
                    top: '0', left: '0',
                    overflow: 'hidden',
                }} ref={parentRef} id='parent'>
                    <motion.div style={{
                        width: '100%', height: '100%',
                        backgroundImage: `url(${ASSETS.LETS_TALK_IMAGE})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom',
                        position: 'absolute',
                        top: '0',
                        y
                    }} />
                </Box>
                <Grid container direction={{ xs: 'column-reverse', lg: 'row' }} gap={{ xs: 10, lg: 1 }} py={11} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Grid item height='100%' component={Paper} xs={12} lg={4} zIndex={1}>
                        <LetsTalkForm />
                    </Grid>
                    <Grid item xs={12} lg={6} zIndex={1}>
                        <LetsTalkText />
                    </Grid>
                </Grid>
            </AnimatePresence>
        </section >
    )
}

export default LetsTalkSection