import { print } from 'graphql';
import gql from 'graphql-tag';


const GET_MY_BLOG_POSTS = gql`
	query GET_MY_BLOG_POSTS {
		dvmRenovatienCollection(limit: 20){
			items {
				title
				slug
				photo1 {
				  image {
					url
				  }
				}
			  }
		}
	}
`;

const blogPostsWithLimit = (limit: number, skip: number, bigsmall: 'big' | 'small' | null, locale: 'en-US' | 'nl-NL') => {
	return bigsmall === 'big' ? gql`query GET_MY_BLOG_POSTS {
		dvmRenovatienCollection(limit: ${limit}, skip: ${skip}, where: { bigsmall: "${bigsmall}", ende: "${locale === 'en-US' ? 'en' : 'nl'}"}){
			total
			items {
				title
				bigsmall
				slug
				photo1 {
				  image {
					url
				  }
				}
			  }
		}
	} 
`: bigsmall === 'small' ? gql`query GET_MY_BLOG_POSTS {
	dvmRenovatienCollection(limit: ${limit}, skip: ${skip}, where: { bigsmall_not: "big",ende: "${locale === 'en-US' ? 'en' : 'nl'}"}){
		total
		items {
			title
			bigsmall
			slug
			photo1 {
			  image {
				url
			  }
			}
		  }
	}
} 
` : gql`query GET_MY_BLOG_POSTS {
	dvmRenovatienCollection(limit: ${limit}, skip: ${skip}, where: { bigsmall_not: "big",ende: "${locale === 'en-US' ? 'en' : 'nl'}"}){
		total
		items {
			title
			bigsmall
			slug
			photo1 {
			  image {
				url
			  }
			}
		  }
	}
} 
`
}

const getBlogPosts = (limit: number, skip: number, bigsmall: 'big' | 'small' | null, locale: 'en-US' | 'nl-NL') => {
	return JSON.stringify({
		query: print(blogPostsWithLimit(limit, skip, bigsmall, locale)),
	});
};
const servicesWithLocale = (locale: 'en-US' | 'nl-NL') => {
	return gql`query GET_MY_SERVICES {
		serviceCollection(where: { ennl: "${locale === 'en-US' ? 'en' : 'nl'}"}){
			total
			items {
				cardTitle
				title
				title2
				title3
				title4
				text
				text2
				text3
				text4
				image {
					url
				}
				image2 {
					url
				}
				image3 {
					url
				}
				image4 {
					url
				}
				imageGalleryCollection{
					items {
						url
					}
				}
			}
		}
	} 
	`
}

const getServices = (locale: 'en-US' | 'nl-NL') => {
	return JSON.stringify({
		query: print(servicesWithLocale(locale)),
	});
};

const getBlogPostsBySlug = (slug: string, locale: 'en-US' | 'nl-NL') => {
	return JSON.stringify({
		query: print(
			gql`
			{
			dvmRenovatienCollection(where: { slug: "${slug}", ende: "${locale === 'en-US' ? 'en' : 'nl'}"}) {
					items {
						title
						slug
						renovationType
						subtitle1
						subtitle2
						subtitle3
							text1 {
							json
						}
							text2 {
							json
						}
							textBlock {
							json
						}
							photo1 {
							  image {
								url
							}
						}
							photo2 {
							  image {
								url
							}
						}
					photoListCollection{
					  items {
						  url
					  }
					}
					}
				}
			}
			`
		),
	});
}


const GET_IMAGES = gql`
	query GET_IMAGES {
		photoCollection{
			items{
			image{
					url
					fileName
					title
					description
				}
			}
		}
	}
	`;

const getImages = () => {
	return JSON.stringify({
		query: print(GET_IMAGES),
	});
};


export { getBlogPosts, getImages, getBlogPostsBySlug, getServices };
