import { Box, Stack, Divider, Grid, Theme, useMediaQuery, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography'
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { loadBlogPostBySlug, loadBlogPosts, selectBlogPosts, selectFullBlogPost, selectLoading } from '../../store/slices/contentfulSlice';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ProjectPostCard from './ProjectPostCard';
import richTextRenderer from '../../app/helpers/richTextRenderer'
import ImageGallery from 'react-image-gallery';
import '../../pages/image-size-overload.css'
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';
import { selectCurrentLanguage } from '../../store/slices/uiSlice';
import CallToRequestQuote from '../common/CallToRequestQuote';


// interface BlogPostProps {
//     title: string;
//     renovationType: string;
//     subtitle1: string;
//     subtitle2: string;
//     subtitle3?: string | null;
//     text1: any;
//     text2: any;
//     textBlock?: string | null;
//     photo1: any;
//     photo2: any;
// }

interface GalleryImage {
    original: string;
    thumbnail: string;
}

const BlogPost = () => {
    const isScreenSmall = useMediaQuery(((theme: Theme) => theme.breakpoints.up('sm')));
    const { slug } = useParams()
    const dispatch = useAppDispatch()
    const loading = useAppSelector(selectLoading);
    const blogPost: any | null = useAppSelector(selectFullBlogPost)?.[0]
    const otherPosts: any[] | null = useAppSelector(selectBlogPosts)
    const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([]);
    const locale = useAppSelector(selectCurrentLanguage)
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const [onLeftNavClick, setOnLeftNavClick] = useState<any>(null);
    const [onRightNavClick, setOnRightNavClick] = useState<any>(null);

    useEffect(() => {
        slug && dispatch(loadBlogPostBySlug(slug, locale))
        dispatch(loadBlogPosts(20, 0, null, locale))
    }, [slug, locale])

    const renderItem = (item: any) => {
        if (item.video) {
            return (
                <div className="image-gallery-image" >
                    <video controls style={{ maxHeight: '600px', width: '100%' }}>
                        <source src={item.video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        }

        return (
            <div className="image-gallery-image">
                <img src={item.original} alt="" style={{ maxWidth: '60vW', maxHeight: '600px' }} />
            </div>
        );
    };

    useEffect(() => {
        if (blogPost) {
            const newGalleryImages = blogPost.photoListCollection.items.map((img: any) => ({
                thumbnail: img.url.includes('videos.ctfassets') ? 'https://img.icons8.com/dusk/64/video.png' : img.url,
                original: img.url,
                ...(img.url.includes('videos.ctfassets') && { video: img.url }) //TODO remove workaround (change ctf model)

            }));
            setGalleryImages(newGalleryImages)
        }
    }, [blogPost])

    return (
        <Box margin='2rem 4rem'>
            {
                (loading || !blogPost) ? <>Currently loading</> : (
                    <>
                        <Typography variant="subtitle1" color="gray" >
                            {blogPost.renovationType}
                        </Typography>
                        <Typography variant="h2" color="initial">
                            {blogPost.title}
                        </Typography>
                        <Divider variant='fullWidth' sx={{ width: '60%' }} />

                        <Grid container marginTop={'1.5rem'} spacing={6}>
                            <Grid item xs={12} sm={6}>
                                <img src={blogPost.photo1.image.url} alt="the first" width='100%' height='auto' />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
                                <Typography variant="h4" color="initial">
                                    {blogPost.subtitle1}
                                </Typography>
                                <Divider variant='fullWidth' sx={{ marginBottom: '1rem' }} />
                                <Typography variant="body1" color="initial">
                                    {richTextRenderer(blogPost.text1.json)}
                                </Typography>
                            </Grid>
                            {
                                isScreenSmall ?
                                    <>
                                        <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' }, }} >
                                            <Typography variant="h4" color="initial">
                                                {blogPost.subtitle2}
                                            </Typography>
                                            <Divider variant='fullWidth' sx={{ marginBottom: '1rem' }} />
                                            <Typography variant="body1" color="initial">
                                                {richTextRenderer(blogPost.text2.json)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <img src={blogPost.photo2.image.url} alt="the first" width='100%' height='auto' />
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <img src={blogPost.photo2.image.url} alt="the first" width='100%' height='auto' />
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' }, }} >
                                            <Typography variant="h4" color="initial">
                                                {blogPost.subtitle2}
                                            </Typography>
                                            <Typography variant="body1" color="initial">
                                                {richTextRenderer(blogPost.text2.json)}
                                            </Typography>
                                        </Grid>
                                    </>

                            }

                            {
                                blogPost.subtitle3 && blogPost.textBlock && (
                                    <Grid item sx={{ margin: { xs: '1rem', sm: ' 2rem 8rem' } }} >
                                        <Typography variant="h4" color="initial">
                                            {blogPost.subtitle3}
                                        </Typography>
                                        <Typography variant="body1" color="initial">
                                            {richTextRenderer(blogPost.textBlock.json)}
                                        </Typography>
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} sx={{ margin: '3rem 0' }}>
                                <CallToRequestQuote color='primary' />
                            </Grid>
                            <Grid item xs={12} sx={{ margin: '3rem 0' }}>
                                {galleryImages && galleryImages.length > 0 &&
                                    <>
                                        <Stack direction='row' justifyContent='space-between' width='100%'>
                                            <IconButton onClick={onLeftNavClick} sx={{ "&:hover": { backgroundColor: "transparent" } }} >
                                                <ArrowBackIosOutlined />
                                            </IconButton>
                                            <ImageGallery renderItem={renderItem} renderPlayPauseButton={(onClick, isPlaying) => <></>} items={galleryImages} thumbnailPosition='right' autoPlay={false}  {...(!isFullscreen ? {
                                                renderRightNav: (onClick, disabled) => {
                                                    setOnRightNavClick(() => onClick);
                                                    return <></>
                                                },
                                                renderLeftNav: (onClick, disabled) => {
                                                    setOnLeftNavClick(() => onClick);
                                                    return <></>
                                                }
                                            } : {})}
                                                onScreenChange={(fullscreen) => {
                                                    setIsFullscreen(fullscreen)
                                                }} />
                                            <IconButton onClick={onRightNavClick} sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                                                <ArrowForwardIosOutlined />
                                            </IconButton>
                                        </Stack>
                                    </>
                                }
                            </Grid>
                            <Grid item xs={12} sx={{ margin: { xs: '1rem', sm: ' 3rem 8rem' } }}>
                                <Typography variant="h4" color="initial">
                                    Other blog posts:
                                </Typography>
                            </Grid>
                        </Grid>
                        <Stack direction={{ xs: 'column', sm: 'row' }} width='100%' justifyContent='space-around' spacing={{ xs: 4, sm: 0 }}>
                            <>
                                {
                                    otherPosts?.filter((instance) => instance.slug !== slug).map((post, index) => {
                                        return (
                                            index < 3 && (
                                                <ProjectPostCard img={post.photo1.image.url} title={post.title} routeTo={`/blog/${post.slug}`} />
                                            )
                                        )
                                    })
                                }
                            </>
                        </Stack>
                    </>
                )
            }
        </Box>
    );
}

export default BlogPost;