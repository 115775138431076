import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Paper, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import { FormattedMessage } from 'react-intl';

interface ServiceCardProps {
    title: string;
    anchor?: string;
    description: string;
    image: string;
    fullSize?: boolean;
    onReadMore: (num: number) => void;
    reverse: boolean;
    index: number;
}

const CarouselServiceCard = ({ title, anchor, description, image, fullSize = false, onReadMore, reverse, index }: ServiceCardProps) => {

    const mediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Stack direction='row'>
            <Card sx={{ padding: 4, width: '70vW', height: 450, flexShrink: 0 }} component={Paper} elevation={4} >
                <Grid container flexDirection={reverse ? "row-reverse" : "row"}>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'space-between', flexDirection: 'column' }}>
                        <Stack direction='column' justifyContent='space-between' height='90%' alignItems={reverse ? 'flex-end' : 'flex-start'}>
                            {!mediumScreen && <Grid width='100%' item xs={12} md={6}>
                                <CardMedia image={image} sx={{ height: '16em' }} />
                            </Grid>}
                            <CardContent sx={{ textAlign: reverse ? 'right' : 'left' }}>
                                <Typography component="a" id={anchor} variant="h5">
                                    {title}
                                </Typography>
                                {mediumScreen && <Typography variant="subtitle1" color="text.secondary" sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '3',
                                    WebkitBoxOrient: 'vertical',
                                    wordBreak: 'break-word'
                                }}>
                                    {description}
                                </Typography>}
                            </CardContent>
                            <CardActions>
                                <Button disableFocusRipple variant='contained' color='primary' fullWidth={!mediumScreen} onClick={() => onReadMore(index)}>
                                    <FormattedMessage id='common.btnReadMore' />
                                </Button>
                            </CardActions>
                        </Stack>
                    </Grid>
                    {mediumScreen && <Grid item xs={12} md={6}>
                        <CardMedia image={image} sx={{ height: 430 }} />
                    </Grid>}

                </Grid>
            </Card >
            <Box sx={{ width: '2rem' }} />
        </Stack>
    )
}

export default CarouselServiceCard