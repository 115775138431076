import BadgesSection from '../components/common/BadgesSection'
import StatisticsSection from '../components/common/StatisticsSection'
import WhyUsSection from '../components/aboutUs/WhyUsSection'
import WhatWeProvideSection from '../components/aboutUs/WhatWeProvideSection'
import Helmet from 'react-helmet'
import HeroSection from '../components/homePage/HeroSection'
import { useIntl } from 'react-intl';
import AboutUsSection from '../components/aboutUs/AboutUsSection'
import OurMissionSection from '../components/aboutUs/OurMissionSection'

const AboutUsPage = () => {
    const intl = useIntl();
    return (
        <>
            <Helmet>
                <title>DVM - About us</title>
                <meta name="description" content="About our family owned, professional, reliable and affordable company. Over ons familiebedrijf, professioneel, betrouwbaar en betaalbaar." />
                <meta name='keywords' content='About, Renovation, Reliable, Trust, Over, Renovatie, Betrouwbaar, Vertrouwen' />
            </Helmet>
            <HeroSection title={intl.formatMessage({ id: 'homePage.heroSection.title' })} subtitle={intl.formatMessage({ id: 'homePage.heroSection.subtitle' })} showContactInfo={true} />
            <WhyUsSection />
            <WhatWeProvideSection />
            <AboutUsSection />
            <OurMissionSection />
            <StatisticsSection />
            <BadgesSection />
        </>
    )
}

export default AboutUsPage