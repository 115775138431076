import MainLayout from '../components/layout/MainLayout'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import ROUTES from '../config/routes'
import AboutUsPage from './AboutUsPage'
import HomePage from './HomePage'
import ContactPage from './ContactPage'
import ServicesPage from './ServicesPage'
import ProjectsPage from './ProjectsPage'
import { useEffect } from 'react'
import BlogPost from '../components/projectsPage/BlogPost'
import { ASSETS } from '../app/constants'

const Pages = () => {
    const { pathname } = useLocation()
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [pathname])

    return (
        <Routes>

            <Route element={<MainLayout />}>
                <Route path="/" element={<Navigate to={ROUTES.HOME} replace={true} />} />
                <Route path={ROUTES.HOME} element={<HomePage />} />
                <Route path={ROUTES.ABOUT_US} element={<AboutUsPage />} />
                <Route path={ROUTES.CONTACT} element={<ContactPage />} />
                <Route path={ROUTES.SERVICES} element={<ServicesPage />} />
                <Route path={ROUTES.PROJECTS} element={<ProjectsPage />} />
                <Route path={'/blog/:slug'} element={<BlogPost />} />
                <Route path={'*'} element={<Navigate to="/" replace />} />
            </Route>
        </Routes>
    )
}

export default Pages