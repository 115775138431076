import { Grid, Stack } from "@mui/material";
import { ASSETS } from "../../app/constants";
import StatisticsCard, { StatisticsCardProps } from "./StatisticsCard";
import { useIntl } from "react-intl";

const StatisticsSection = () => {
    const intl = useIntl()
    const cardContent: StatisticsCardProps[] = [
        {
            icon: ASSETS.WORKER_ICON,
            title: intl.formatMessage({ id: 'statisticsComponent.card11' }),
            subtitle: intl.formatMessage({ id: 'statisticsComponent.card12' }),
            number: '8'
        },
        {
            icon: ASSETS.BRIEFCASE_ICON,
            title: intl.formatMessage({ id: 'statisticsComponent.card21' }),
            subtitle: intl.formatMessage({ id: 'statisticsComponent.card22' }),
            number: '12'
        },
        {
            icon: ASSETS.SMILEY_ICON,
            title: intl.formatMessage({ id: 'statisticsComponent.card31' }),
            subtitle: intl.formatMessage({ id: 'statisticsComponent.card32' }),
            number: '150'
        },
        {
            icon: ASSETS.ROCKET_ICON,
            title: intl.formatMessage({ id: 'statisticsComponent.card41' }),
            subtitle: intl.formatMessage({ id: 'statisticsComponent.card42' }),
            number: '180'
        },
    ]

    const styles = {
        container: {
            height: { sm: '100%', md: '29rem' },
            padding: { sm: '10% 0', md: '0% 0% 0% 0%' },
        }
    }

    return (
        <Grid container width='100%' alignItems='center' justifyContent='center' flexDirection='row' bgcolor='primary.main' sx={styles.container}>
            {cardContent.map((content, index) => (
                <Grid item xs={6} sm={3}>
                    <StatisticsCard
                        key={index}
                        plus={index !== 0}
                        thousand={index === 3}
                        icon={content.icon}
                        title={content.title}
                        subtitle={content.subtitle}
                        number={content.number}
                    />
                </Grid>
            ))}
        </Grid>

    );
}

export default StatisticsSection;