import { Grid, List, ListItem, ListItemIcon, ListItemText, styled, Typography, useTheme } from "@mui/material";
import { ASSETS } from "../../app/constants";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { FormattedMessage } from "react-intl";

const WhatWeProvideSection = () => {
    const theme = useTheme()

    const Wrapper = styled('section')({
        margin: 0,
    })

    const styles = {
        backgroundImage: {
            [theme.breakpoints.down('lg')]: {
                backgroundSize: 'contain',
                height: '30rem',
                minHeigh: '18rem'
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            display: 'flex',
            justifyContent: 'center',
            backgroundImage: `url(${ASSETS.ROOM_PAINTING})`,
            alignItems: 'space-between',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        },
    }

    return (
        <Wrapper>
            <Grid container
                sx={{
                    padding: '5rem',
                    [theme.breakpoints.down('sm')]: {
                        padding: '2.5rem',
                    },
                }}>
                <Grid item xs={12} sm={6} padding={'0 2rem 0 0'}>
                    <Typography variant="h5" color="primary" fontWeight='bold'>
                        <FormattedMessage id='aboutUsPage.eyebrowText2' />
                    </Typography>
                    <Typography variant="h3" color="initial" lineHeight='8rem'>
                        <FormattedMessage id='aboutUsPage.subtitle2' />
                    </Typography>
                    <Typography variant="h4" fontWeight={550} fontSize='1.5rem' color="black" lineHeight='2.5rem'>
                        <FormattedMessage id='aboutUsPage.sentence' />
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <FiberManualRecordIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1" color="initial" sx={{ fontSize: '1.2rem' }}>
                                    <FormattedMessage id='aboutUsPage.li1' />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FiberManualRecordIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1" color="initial" sx={{ fontSize: '1.2rem' }}>
                                    <FormattedMessage id='aboutUsPage.li2' />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FiberManualRecordIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1" color="initial" sx={{ fontSize: '1.2rem' }}>
                                    <FormattedMessage id='aboutUsPage.li3' />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FiberManualRecordIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1" color="initial" sx={{ fontSize: '1.2rem' }}>
                                    <FormattedMessage id='aboutUsPage.li4' />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FiberManualRecordIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1" color="initial" sx={{ fontSize: '1.2rem' }}>
                                    <FormattedMessage id='aboutUsPage.li5' />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FiberManualRecordIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1" color="initial" sx={{ fontSize: '1.2rem' }}>
                                    <FormattedMessage id='aboutUsPage.li6' />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Grid xs={12} md={6} item sx={styles.backgroundImage} />
            </Grid>
        </Wrapper >
    );
}

export default WhatWeProvideSection;