import { createTheme, responsiveFontSizes, Theme, ThemeOptions } from '@mui/material/styles';
import '@fontsource/poppins';

declare module '@mui/material/styles/createPalette' {
	interface Palette {
		tertiary: Palette['primary'];
	}
	interface PaletteOptions {
		tertiary: PaletteOptions['primary'];
	}
}
declare module '@mui/material/Stack' {
	export interface StackPropsColorOverrides {
		tertiary: true
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		tertiary: true;
	}
}

declare module '@mui/material/styles' {
	interface CustomTheme extends Theme {
		[key: string]: any; //
	}
	// allow configuration using `createTheme`
	interface CustomThemeOptions extends ThemeOptions {
		[key: string]: any; //
	}
	export function createTheme(options?: CustomThemeOptions): CustomTheme;
}

export const RENOVATIE_PRIMARY_BLUE = '#1507AB'

export const RENOVATIE_SECONDARY_NEUTRAL_00 = '#FFFFFF'
export const RENOVATIE_SECONDARY_NEUTRAL_10 = '#FAFAFD'

export const RENOVATIE_TERTIARY_BLACK_30 = '#1F1F1F'


const FONT_WEIGHT_EXTRABOLD = 800
const FONT_WEIGHT_BOLD = 700
const FONT_WEIGHT_LIGHT = 400
// const FONT_WEIGHT_NORMAL = 500

const typography = {
	fontSize: 16,
	fontFamily: 'Poppins',
	h1: {
		fontSize: '4.687rem',
		fontWeight: FONT_WEIGHT_EXTRABOLD,
		letterSpacing: '0rem',
	},
	h2: {
		fontSize: '4.0625rem',
		fontWeight: FONT_WEIGHT_EXTRABOLD,
		letterSpacing: '0rem',
	},
	h3: {
		fontSize: '3rem',
		fontWeight: FONT_WEIGHT_EXTRABOLD,
		letterSpacing: '0rem',
	},
	h4: {
		fontSize: '1.875rem',
		fontWeight: 400,
		letterSpacing: '0rem',
	},
	h5: {
		fontSize: '1.4rem',
		fontWeight: 500,
		letterSpacing: '0rem',
	},
	h6: {
		fontSize: '1rem',
		fontWeight: 400,
		letterSpacing: '0rem',
	},
	body1: {
		fontSize: '0.920rem',
		fontWeight: 400,
		letterSpacing: '0rem',
	},
	body2: {
		fontSize: '0.875rem',
		fontWeight: FONT_WEIGHT_BOLD,
		letterSpacing: '0rem',
	},
	button: {
		fontSize: '1.125rem',
		fontWeight: FONT_WEIGHT_BOLD,
		letterSpacing: '0rem',
	},
	overline: {
		fontSize: '1rem',
		fontWeight: FONT_WEIGHT_EXTRABOLD,
		letterSpacing: '0rem',

	},
	caption: {
		fontSize: '0.8125rem',
		fontWeight: FONT_WEIGHT_LIGHT,
		letterSpacing: '0rem'
	}
};

const palette = {
	primary: {
		main: RENOVATIE_PRIMARY_BLUE,
	},
	secondary: {
		main: RENOVATIE_SECONDARY_NEUTRAL_00,
		light: RENOVATIE_SECONDARY_NEUTRAL_10
	},
	tertiary: {
		main: RENOVATIE_TERTIARY_BLACK_30
	}
};

const theme = responsiveFontSizes(createTheme({
	typography: typography,
	palette: palette,
	components: {
		MuiButton: {
			styleOverrides: {
				root: () => ({
					borderRadius: 6,
					paddingLeft: 37,
					paddingRight: 37,
					paddingTop: 17,
					paddingBottom: 17,
					textTransform: 'capitalize',
				}),
			},
		},
	},
}));

export default theme;
