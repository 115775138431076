import { Menu, MenuItem } from '@mui/material';
import ROUTES from '../../../config/routes';
import { useNavigate } from 'react-router-dom';

interface ServicesMenuProps {
    open: boolean;
    anchorEl: any;
    onClose: () => void;
    serviceOptions: string[];
}

export const cleanToTechName = (name: string | undefined) => {
    if (name === undefined || name === null) return undefined;

    const cleanedName = name
        ?.replace(/^[^a-z]+/i, '') // remove non-alphanumeric characters from start
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9]+/g, '_') // replace non-alphanumeric characters with underscores
        .replace(/([a-z0-9])([A-Z])/g, '$1_$2') // convert to snake case
        .replace(/__+/g, '_') // replace consecutive underscores
        .replace(/^_/, ''); // remove underscore at the start

    return cleanedName === '' ? undefined : cleanedName;
};


const ServicesMenu = ({ open, serviceOptions, anchorEl, onClose }: ServicesMenuProps) => {
    const navigate = useNavigate();

    return (
        <>
            <Menu open={open} id="language-menu" anchorEl={anchorEl} onClose={onClose}>
                {serviceOptions.map((so) =>
                    <MenuItem
                        onClick={() => {
                            onClose();
                            navigate(ROUTES.SERVICES + `#${cleanToTechName(so)?.toLowerCase()}`) ////TODO: {@Anyone} Serious tech debt, figure out a way to anchor from a different page without a double route
                        }}
                    >
                        <a href={`/services#${cleanToTechName(so)?.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }}>
                            {so}
                        </a>
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}

export default ServicesMenu