import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, Typography } from '@mui/material';
import { useAppSelector } from '../../store/configureStore';
import { selectCurrentLanguage } from '../../store/slices/uiSlice';
import React, { useState } from 'react';

import LanguageMenu from './LanguageMenu';

interface LanguagePickerProps {
    color: 'white' | 'black';
}

const LanguagePicker = ({ color }: LanguagePickerProps) => {
    const selectedLanguage = useAppSelector(selectCurrentLanguage);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                sx={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                disableRipple
                onClick={handleClick}
            >
                <LanguageIcon fontSize="small" sx={{ color: color }} />
                <Typography sx={{ color: color }} variant="button">
                    {selectedLanguage?.split('-')[0].toUpperCase()}
                </Typography>
                <KeyboardArrowDownIcon fontSize="small" sx={{ color: 'white' }} />
            </IconButton>
            <LanguageMenu anchorEl={anchorEl} open={open} onClose={handleClose} />
        </>
    );
}

export default LanguagePicker;
