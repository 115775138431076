import { useCallback, useEffect, useRef } from 'react'
import { useSpring, animated as a, to } from 'react-spring'
import useWindowScroll from '@react-hook/window-scroll'
import useScrollWidth from './useScrollWidth'

const ScrollCarousel = ({ children }) => {
  const refHeight = useRef(null)
  const refTransform = useRef(null)


  const { scrollWidth } = useScrollWidth(refTransform)

  const scrollY = useWindowScroll(45)
  const [{ st, xy }, set] = useSpring(() => ({ st: 0, xy: [0, 0] }))

  useEffect(() => {
    set({ st: scrollY })
  }, [scrollY, set])

  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => set({ xy: [x - window.innerWidth / 2, y - window.innerHeight / 2] }), [])

  const top = refHeight.current ? refHeight.current.offsetTop : 0
  const width = refHeight.current ? refHeight.current.offsetWidth : 0

  const elHeight = scrollWidth - (window.innerWidth - window.innerHeight) + width * 0.5  // scroll away when final viewport width is 0.5 done

  const interpTransform = to([st, xy], (o, xy) => {
    const mouseMoveDepth = 40
    const x = width - (top - o) - width

    if (x < -window.innerHeight - width * 0.5) {
      return `translate3d(${window.innerHeight}px, 0, 0)`
    }

    if (Math.abs(x) > elHeight) {
      return `translate3d(${elHeight}px, 0, 0)`
    }

    return `translate3d(${-x + -xy[0] / mouseMoveDepth}px, ${-xy[1] / mouseMoveDepth}px, 0)`
  })

  return (
    <div onMouseMove={onMouseMove} ref={refHeight} style={{ height: elHeight }}>
      <div style={{ position: 'sticky', top: '20%', overflow: 'hidden' }}>
        <a.div style={{ transform: interpTransform, display: 'flex', willChange: 'transform' }} ref={refTransform}>
          {children}
        </a.div>
      </div>
    </div >
  )
}

export default ScrollCarousel