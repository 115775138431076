import { Stack, Typography, Box } from "@mui/material";
import CallToRequestQuote from "../../../components/common/CallToRequestQuote";
import { ASSETS } from "../../../app/constants";
import FooterContactOptions from "../../../components/common/FooterContactOptions";
import { RENOVATIE_PRIMARY_BLUE } from "../../../styles/theme";
import FollowUsNavigation from "./FollowUsNavigation";

const MobileFooter = () => {
    const footerRowsDefinitions = [{
        title: 'Company',
        routeTo: [
            {
                label: 'About Us',
                routeTo: '/about-us'
            },
            {
                label: 'Projects',
                routeTo: '/projects'
            },
            {
                label: 'Get a Quote',
                routeTo: '/get-quote'
            },
            {
                label: 'Contact',
                routeTo: '/contact'
            }
        ]
    }, {
        title: 'Services',
        routeTo: [
            {
                label: 'Renovation',
                routeTo: '/about-us'
            },
            {
                label: 'Plaster work',
                routeTo: '/projects'
            },
            {
                label: 'Custom kitchen molds',
                routeTo: '/get-quote'
            },
            {
                label: 'Interior & exterior decoration',
                routeTo: '/contact'
            },
            {
                label: 'Modern drywalls and ceilings',
                routeTo: '/contact'
            },
            {
                label: 'Floor laminate and carpets',
                routeTo: '/contact'
            },
            {
                label: 'Painting work',
                routeTo: '/contact'
            },
            {
                label: 'Custom veranda',
                routeTo: '/contact'
            },
            {
                label: 'Innovative advices',
                routeTo: '/contact'
            },
        ]
    }]

    const styles = {
        imageGrid: {
            backgroundImage: `url(${ASSETS.FOOTER_BACKGROUND})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'bottom'
        }
    }

    return (
        <Stack direction={'column'}>
            <Box sx={styles.imageGrid} padding={6}>
                <Typography color='secondary' fontWeight={900} variant='h3' pb={2}>Contact us</Typography>
                <FooterContactOptions spacing={3} />
            </Box>
            <Box height='100%' width='100%' padding={4} sx={{ backgroundColor: RENOVATIE_PRIMARY_BLUE }}>
                <Stack alignItems='center' justifyContent='center' spacing={6}>
                    <img alt='footer-logo' height={102} width={271} src={ASSETS.RNV_FOOTER_LOGO} />
                    <FollowUsNavigation />
                    <Stack direction='row' justifyContent='space-between' alignContent='center' width='100%' spacing={4}>
                        <CallToRequestQuote />
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    );
}

export default MobileFooter;