import { Avatar, Box, Button, Card, CardContent, CardMedia, Paper, Stack, Typography } from '@mui/material'
import { ASSETS } from '../../../app/constants'
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import { useEffect, useState } from 'react';


const randomColor = () => {
    let hex = Math.floor(Math.random() * 0xFFFFFF);
    let color = "#" + hex.toString(16);

    return color;
}
interface TestimonialCardProps {
    title: string;
    subtitle: string;
    description: string;
    stars: number;
    image?: string; //TODO: @Rokva not optional
}

const TestimonialCard = ({ title, subtitle, description, stars, image = ASSETS.SERVICE_CARD_IMAGE }: TestimonialCardProps) => {
    const [rc, setRc] = useState<string>('#ffffff')
    const filledStars = []
    const emptyStars = []

    for (let i = 0; i < stars; i++) {
        filledStars.push(<StarIcon sx={{ color: 'gold' }} />)
    }

    for (let i = 0; i < 5 - stars; i++) {
        emptyStars.push(<StarOutlineIcon sx={{ color: 'gold' }} />)
    }

    useEffect(() => {
        setRc(randomColor())
    }, [])

    return (
        <Card sx={{ display: 'flex', padding: 4, width: 400, height: 400, justifyContent: 'space-between', borderRadius: 4 }} component={Paper} elevation={0} >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Stack direction='column' spacing={2} width='100%' alignItems='flex-start' justifyContent='space-between' height='100%'>
                        <Box >
                            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                                <Avatar sx={{ bgcolor: `${rc}` }}>{title.at(0)}</Avatar>
                                <Stack direction='column' spacing={0.5}>
                                    <Typography component="div" variant="body2">
                                        {title}
                                    </Typography>
                                    <Typography component="div" variant="caption">
                                        {subtitle}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <br />
                            <Typography variant="caption" color="text.secondary" component="div">
                                {description ? description !== '' ? description : 'No comment' : 'No comment'}
                            </Typography>
                        </Box>
                        <Stack direction='row' >
                            {
                                filledStars.map((fs) => fs)
                            }
                            {
                                emptyStars.length + filledStars.length < 5 && <StarHalfIcon sx={{ color: 'gold' }} />

                            }
                            {
                                emptyStars.map((es) => es)
                            }
                        </Stack>
                    </Stack>

                </CardContent>
            </Box>
        </Card>
    )
}

export default TestimonialCard