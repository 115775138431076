import { Box, Stack, Typography } from '@mui/material'
import { ASSETS } from '../../app/constants';
import React from 'react'
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface ProjectPostCardProps {
    img?: string;
    title?: string;
    routeTo?: string;
}

const ProjectPostCard = ({ img = ASSETS.PROJECT_POST_THUNBNAIL, title = 'Project', routeTo = 'none-yet' }: ProjectPostCardProps) => {
    return (
        <Stack sx={{ width: 'fit-content' }} direction='column' alignItems='center' spacing={1}>
            <img src={img} width='220' height='155' style={{ borderRadius: '10px' }} />
            <Typography fontSize='1.25rem' lineHeight='1.5rem' textAlign='center' maxWidth={200} sx={{ wordBreak: 'break-word' }} >{title}</Typography>
            <Typography component={Link} to={routeTo} fontSize='1.2rem' lineHeight='1.7rem' >
                <FormattedMessage id='common.viewProject' />
            </Typography>
        </Stack>
    )
}

export default ProjectPostCard