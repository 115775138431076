import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Button, Theme, useMediaQuery } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TestimonialCard from './TestimonialCard';

const TestimonialsCarousel = ({ data }: any) => {
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const [FlowDirection, setFlowDirection] = useState(true)
    const [CenterId, setCenterId] = useState(0)
    const [LeftId, setLeftId] = useState(data.length - 1)
    const [RightId, setRightId] = useState(1)
    const [disabled, setDisabled] = useState(false)

    const disable = () => {
        setDisabled(true)
        setTimeout(() => {
            setDisabled(false)
        }, 500)
    }

    const nextBtn = () => {
        disable()
        if (LeftId === data.length - 1) {
            setLeftId(0)
        } else {
            setLeftId(LeftId + 1)
        }
        if (CenterId === data.length - 1) {
            setCenterId(0)
        } else {
            setCenterId(CenterId + 1)
        }

        if (RightId === data.length - 1) {
            setRightId(0)
        } else {
            setRightId(RightId + 1)
        }
        setFlowDirection(true)
    }
    const prevBtn = () => {
        disable()
        setFlowDirection(false)
        if (LeftId === 0) {
            setLeftId(data.length - 1)
        } else {
            setLeftId(LeftId - 1)
        }
        if (CenterId === 0) {
            setCenterId(data.length - 1)
        } else {
            setCenterId(CenterId - 1)
        }
        if (RightId === 0) {
            setRightId(data.length - 1)
        } else {
            setRightId(RightId - 1)
        }
    }
    const variants = {
        center: {
            x: '0rem',
            opacity: 1,
            scale: 1,
            zIndex: '5',
            boxShadow: 'unset',
            transition: {
                type: 'spring',
                duration: 1,
            },
        },
        left: {
            x: '-26rem',
            opacity: 1,
            scale: 1,
            zIndex: '4',
            boxShadow: 'unset',
            transition: {
                type: 'spring',
                duration: 1,
            },
        },
        right: {
            x: '26rem',
            opacity: 1,
            scale: 1,
            boxShadow: 'unset',
            zIndex: '3',
            transition: {
                type: 'spring',
                duration: 1,
            },
        },
        rightHidden: {
            x: '34rem',
            scale: 0,
            opacity: 0,
        },
        leftHidden: {
            x: '-34rem',
            scale: 0,
            opacity: 0,
        },
    }
    return (
        <motion.div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
        }}>

            <motion.div style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <AnimatePresence>
                    <motion.div
                        key={LeftId}
                        variants={variants}
                        initial={FlowDirection ? 'center' : 'leftHidden'}
                        animate="left"
                        exit={'leftHidden'}
                        style={{
                            position: 'absolute',
                        }}
                    >
                        {largeScreen && <TestimonialCard stars={data[LeftId].stars} title={data[LeftId].title} description={data[LeftId].description} subtitle={data[CenterId].subtitle} />}
                    </motion.div>
                    <motion.div
                        variants={variants}
                        key={CenterId}
                        initial={FlowDirection ? 'right' : 'left'}
                        animate="center"
                        style={{
                            position: 'absolute',
                        }}
                    >
                        <TestimonialCard stars={data[CenterId].stars} title={data[CenterId].title} description={data[CenterId].description} subtitle={data[CenterId].subtitle} />
                    </motion.div>
                    <motion.div
                        key={RightId}
                        variants={variants}
                        initial={FlowDirection ? 'rightHidden' : 'center'}
                        animate="right"
                        exit={'rightHidden'}
                        style={{
                            position: 'absolute',
                        }}
                    >
                        {largeScreen && <TestimonialCard stars={data[RightId].stars} title={data[RightId].title} description={data[RightId].description} subtitle={data[CenterId].subtitle} />}
                    </motion.div>
                </AnimatePresence>
            </motion.div>
            <div style={{
                marginTop: '30rem',
                marginLeft: 'auto',
                marginRight: 'auto'
            }}>
                <Button component={motion.button}
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        type: 'spring',
                        duration: 0.5,
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.8 }}

                    className="bwd-btn"
                    disableRipple={true}
                    disableFocusRipple
                    focusRipple={false}
                    disableTouchRipple
                    sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    onClick={prevBtn}
                    disabled={disabled}
                >
                    <ArrowBackIcon sx={{ border: "solid 2px", borderRadius: 10 }} />
                </Button>
                <Button component={motion.button} initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        type: 'spring',
                        duration: 0.5,
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.8 }}
                    sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    className="fwd-btn"
                    onClick={nextBtn}
                    disabled={disabled}
                >


                    <ArrowForwardIcon sx={{ border: "solid 2px", borderRadius: 10 }} />
                </Button>
            </div>
        </motion.div>
    )
}

export default TestimonialsCarousel