import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import renovatie from '../../services/renovatieService';
import { AppDispatch, RootState } from '../configureStore';


interface ContentfulSliceState {
    loading: boolean;
    loadingBig: boolean;
    loadingSmall: boolean;
    blogPosts: any[] | null; //TODO Define blog posts type, or possibly codegen from contentful schema
    blogPostsSmall: any[] | null; //TODO Define blog posts type, or possibly codegen from contentful schema
    blogPostsBig: any[] | null; //TODO Define blog posts type, or possibly codegen from contentful schema
    postNumber: number | null;
    postNumberSmall: number | null;
    postNumberBig: number | null;
    images: string[] | null;
    services: any[] | null;
    currentPost: any | null;
}

export interface BlogPostResponse {
    items: any[] | null;
    total: number | null;
}

// Initial state for the slice
const initialState: ContentfulSliceState = {
    loading: false,
    loadingSmall: false,
    loadingBig: false,
    blogPosts: null,
    blogPostsSmall: null,
    blogPostsBig: null,
    postNumberSmall: null,
    postNumber: null,
    postNumberBig: null,
    images: null,
    currentPost: null,
    services: null,
};

// Actual Slice
export const contentfulSlice = createSlice({
    name: 'contentful',
    initialState,
    reducers: {
        loadBlogPostsRequested: (state: ContentfulSliceState, action: PayloadAction<'big' | 'small' | null>) => {
            if (action.payload === 'big') //FIXME Ovo je greska - reducer treba samo da menja state, ova logika treba da bude na biznis sloju ( thunkovi ) - napraviti nove reducere za svaki uslov *loadBogBlogPostsRequested *loadSmallBlogPostsRequested
                state.loadingBig = true;
            else if (action.payload === 'small')
                state.loadingSmall = true;
            else
                state.loading = true;
            state.blogPosts = null;
        },
        loadBlogPostsFailed: (state: ContentfulSliceState, action: PayloadAction<'big' | 'small' | null>) => {
            if (action.payload === 'big') //FIXME - || -
                state.loadingBig = false;
            else if (action.payload === 'small')
                state.loadingSmall = false;
            else
                state.loading = false;
        },
        blogPostsLoaded: (state: ContentfulSliceState, action: PayloadAction<{ response: BlogPostResponse, bigsmall: 'big' | 'small' | null }>) => {
            if (action.payload.bigsmall === 'big') { //FIXME - || -
                state.blogPostsBig = action.payload.response.items
                state.postNumberBig = action.payload.response.total

                state.loadingBig = false;
            }
            else if (action.payload.bigsmall === 'small') {

                state.blogPostsSmall = action.payload.response.items
                state.postNumberSmall = action.payload.response.total
                state.loadingSmall = false;
            }
            else {
                state.loading = false;
                state.blogPosts = action.payload.response.items
                state.postNumber = action.payload.response.total
            }
        },
        loadImagesRequested: (state: ContentfulSliceState) => {
            state.loading = true;
            state.images = null;
        },
        loadImagesFailed: (state: ContentfulSliceState) => {
            state.loading = false;
        },
        imagesLoaded: (state: ContentfulSliceState, action: PayloadAction<string[]>) => {
            state.loading = false;
            state.images = action.payload
        },
        loadServicesRequested: (state: ContentfulSliceState) => {
            state.loading = true;
            state.services = null;
        },
        loadServicesFailed: (state: ContentfulSliceState) => {
            state.loading = false;
        },
        servicesLoaded: (state: ContentfulSliceState, action: PayloadAction<any[]>) => {
            state.loading = false;
            state.services = action.payload
        },
        loadFullBlogPostRequested: (state: ContentfulSliceState) => {
            state.loading = true;
            state.currentPost = null;
        },
        loadFullBlogPostFailed: (state: ContentfulSliceState) => {
            state.loading = false;
        },
        fullBlogPostLoaded: (state: ContentfulSliceState, action: PayloadAction<any>) => {
            state.loading = false;
            state.currentPost = action.payload
        },
    },
});

export const {
    loadBlogPostsRequested,
    loadBlogPostsFailed,
    blogPostsLoaded,
    loadImagesRequested,
    loadImagesFailed,
    imagesLoaded,
    loadFullBlogPostRequested,
    loadFullBlogPostFailed,
    loadServicesRequested,
    loadServicesFailed,
    servicesLoaded,
    fullBlogPostLoaded,
} = contentfulSlice.actions;

/////////////////////
// 	 	THUNKS	   //
/////////////////////


export const loadBlogPosts = (limit: number, skip: number, bigsmall: 'big' | 'small' | null, locale: 'en-US' | 'nl-NL') =>
    async (dispatch: AppDispatch) => {
        dispatch(loadBlogPostsRequested(bigsmall));
        const renovatieService = new renovatie();

        const { response, errors } = await renovatieService.contentful.loadBlogPosts(limit, skip, bigsmall, locale);

        if (errors)
            return dispatch(loadBlogPostsFailed(bigsmall))

        return dispatch(blogPostsLoaded({ response, bigsmall }));
    };
export const loadServices = (locale: 'en-US' | 'nl-NL') =>
    async (dispatch: AppDispatch) => {
        dispatch(loadServicesRequested());
        const renovatieService = new renovatie();

        const { response, errors } = await renovatieService.contentful.loadServices(locale);

        if (errors)
            return dispatch(loadServicesFailed(errors))

        return dispatch(servicesLoaded(response));
    };

export const loadImages = () =>
    async (dispatch: AppDispatch) => {
        dispatch(loadImagesRequested());
        const renovatieService = new renovatie();

        const { images, errors } = await renovatieService.contentful.loadImages();

        if (errors)
            return dispatch(loadImagesFailed())

        return dispatch(imagesLoaded(images));
    };

export const loadBlogPostBySlug = (slug: string, locale: 'en-US' | 'nl-NL') =>
    async (dispatch: AppDispatch) => {
        dispatch(loadFullBlogPostRequested());
        const renovatieService = new renovatie();

        const { blog_post, errors } = await renovatieService.contentful.getBlogPostBySlug(slug, locale);

        if (errors)
            return dispatch(loadFullBlogPostFailed())

        return dispatch(fullBlogPostLoaded(blog_post));
    };

export const selectBlogPosts = (state: RootState): any[] | null => state.contentful.blogPosts;
export const selectServices = (state: RootState): any[] | null => state.contentful.services;
export const selectBlogPostsBig = (state: RootState): any[] | null => state.contentful.blogPostsBig;
export const selectBlogPostsSmall = (state: RootState): any[] | null => state.contentful.blogPostsSmall;
export const selectFullBlogPost = (state: RootState): any[] | null => state.contentful.currentPost;
export const selectLoading = (state: RootState): boolean => state.contentful.loading;
export const selectLoadingBig = (state: RootState): boolean => state.contentful.loadingBig;
export const selectLoadingSmall = (state: RootState): boolean => state.contentful.loadingSmall;
export const selectImages = (state: RootState): any[] | null => state.contentful.images;
export const selectPostNumber = (state: RootState): number | null => state.contentful.postNumber
export const selectPostNumberBig = (state: RootState): number | null => state.contentful.postNumberBig
export const selectPostNumberSmall = (state: RootState): number | null => state.contentful.postNumberSmall

export default contentfulSlice.reducer;
