import { Menu, MenuItem } from '@mui/material';
import { useAppDispatch } from '../../store/configureStore';
import { setLanguage } from '../../store/slices/uiSlice';

interface LanguagePickerProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => any;
}

const LanguageMenu = ({ open, anchorEl, onClose }: LanguagePickerProps) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <Menu open={open} id="language-menu" anchorEl={anchorEl} onClose={onClose}>
                <MenuItem
                    onClick={() => {
                        dispatch(setLanguage('nl-NL'));
                        onClose();
                    }}
                >
                    Dutch (NL)
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        dispatch(setLanguage('en-US'));
                        onClose();
                    }}
                >
                    English (US)
                </MenuItem>
            </Menu>
        </>
    );
};

export default LanguageMenu;
