import HeroSection from '../components/homePage/HeroSection'
import React, { useEffect, useRef, useState } from 'react'
import { RENOVATIE_SECONDARY_NEUTRAL_10 } from '../styles/theme'
import VerticalSectionContainer from '../components/common/VerticalSectionContainer'
import { Box, Button, Dialog, DialogContent, IconButton, Skeleton, Stack } from '@mui/material'
import ProjectPostsSection from '../components/projectsPage/ProjectPostsSection'
import { useAppDispatch, useAppSelector } from '../store/configureStore'
import { loadBlogPosts, loadImages, selectBlogPosts, selectBlogPostsBig, selectBlogPostsSmall, selectImages, selectLoading, selectLoadingBig, selectLoadingSmall } from '../store/slices/contentfulSlice'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import CloseIcon from '@mui/icons-material/Close';
import ImageGallery from 'react-image-gallery';
import ReactPlayer from 'react-player';


import './image-size-overload.css'
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material'
import { selectCurrentLanguage } from '../store/slices/uiSlice'

interface GalleryImage {
    original: string;
    thumbnail: string;
}

const ProjectsPage = () => {
    const smallProjectsRef = useRef<HTMLDivElement | null>(null)
    const bigProjectsRef = useRef<HTMLDivElement | null>(null)
    const blogPostsBig = useAppSelector(selectBlogPostsBig) as any[];
    const blogPostsSmall = useAppSelector(selectBlogPostsSmall) as any[];
    const dispatch = useAppDispatch();
    const loadingBig = useAppSelector(selectLoadingBig);
    const loading = useAppSelector(selectLoading);
    const loadingSmall = useAppSelector(selectLoadingSmall);
    const [currentBigPage, setCurrentBigPage] = useState<number>(1);
    const [currentSmallPage, setCurrentSmallPage] = useState<number>(1);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const locale = useAppSelector(selectCurrentLanguage)
    const [onLeftNavClick, setOnLeftNavClick] = useState<any>(null);
    const [onRightNavClick, setOnRightNavClick] = useState<any>(null);

    const intl = useIntl();
    const images = useAppSelector(selectImages);
    const [open, setOpen] = useState(false)
    const [active, setActive] = useState(0)
    const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([]);

    const handlePageChangeBig = (page: number) => {
        setCurrentBigPage(page)
    }
    const handlePageChangeSmall = (page: number) => {
        setCurrentSmallPage(page)
    }

    const renderItem = (item: any) => {
        if (item.video) {
            return (
                <div className="image-gallery-image" style={{ display: 'flex', justifyContent: 'center' }}>
                    <ReactPlayer
                        url={item.video}
                        controls
                        width="60vW"
                        height="600px"
                    />
                </div>
            );
        }

        return (
            <div className="image-gallery-image">
                <img src={item.original} alt="" style={{ maxWidth: '60vW', maxHeight: '600px', position: 'relative', zIndex: 9998 }} />
            </div>
        );
    };

    useEffect(() => {
        dispatch(loadBlogPosts(10, 0, 'big', locale));
        dispatch(loadBlogPosts(10, 0, 'small', locale));
        dispatch(loadImages());
    }, [locale, dispatch])

    useEffect(() => {
        if (images) {
            const distinctFileNames = [] as any;
            const distinctImages = images.reduce((acc, { image }) => {
                if (distinctFileNames.includes(image.fileName)) return acc;

                distinctFileNames.push(image.fileName);

                return [...acc, { image: image }]
            }, []);

            const newGalleryImages = distinctImages.map((img: any) => ({
                thumbnail: img.image.url.includes('videos.ctfassets') ? 'https://img.icons8.com/dusk/64/video.png' : img.image.url,
                original: img.image.url,
                ...(img.image.url.includes('videos.ctfassets') && { video: img.image.url }) //TODO remove workaround (change ctf model)
            }));
            setGalleryImages(newGalleryImages);
        }
    }, [images]);

    const onClose = () => {
        setOpen(false)
    }

    const fetchSmallBlogPosts = (page: number) => {
        dispatch(loadBlogPosts(10, (page - 1) * 10, 'small', locale))
    }
    const fetchBigBlogPosts = (page: number) => {
        dispatch(loadBlogPosts(10, (page - 1) * 10, 'big', locale))
    }

    return (
        <>
            <Helmet>
                <title>DVM - Projects</title>
                <meta name="description" content="Check out the amazing renovation projects we've done in the past! Bekijk de geweldige renovatieprojecten die we in het verleden hebben gedaan!" />
                <meta name='keywords' content="Projects, Renovation, Satisfied, Blogs, Gallery, Projecten, Renovatie, Tevreden, Galerij,"></meta>
            </Helmet>
            <Dialog open={open} onClose={onClose} scroll='body' fullWidth maxWidth='md' transitionDuration={800} >
                <DialogContent >
                    <Box display='flex' sx={{ justifyContent: 'flex-end', position: 'absolute', width: '100%', top: '5%', right: '5%' }}>
                        <IconButton onClick={onClose} >
                            <CloseIcon color='secondary' />
                        </IconButton>
                    </Box>
                    <img src={images?.at(active).image.url} alt='gallery element' style={{ height: 'auto', width: '100%', borderRadius: '1rem' }} />
                </DialogContent>
            </Dialog >
            <HeroSection title={intl.formatMessage({ id: "projectsPage.heroSection.title" })} subtitle={intl.formatMessage({ id: "projectsPage.heroSection.subtitle" })} showContactInfo={true} />
            <VerticalSectionContainer ref={smallProjectsRef} titlePosition='top' eyebrowText={intl.formatMessage({ id: "projectsPage.verticalSectionOurWork.eyebrow" })} title={intl.formatMessage({ id: "projectsPage.verticalSectionOurWork.small" })} backgroundColor={RENOVATIE_SECONDARY_NEUTRAL_10}>
                {loadingSmall ? <Skeleton width={500} height={200} /> : <ProjectPostsSection titleRef={smallProjectsRef} bigsmall='small' blogPosts={blogPostsSmall} fetch={fetchSmallBlogPosts} currentPage={currentSmallPage} onPageChange={handlePageChangeSmall} />}
            </VerticalSectionContainer >
            <VerticalSectionContainer ref={bigProjectsRef} titlePosition='top' eyebrowText={intl.formatMessage({ id: "projectsPage.verticalSectionOurWork.eyebrow" })} title={intl.formatMessage({ id: "projectsPage.verticalSectionOurWork.big" })} backgroundColor={RENOVATIE_SECONDARY_NEUTRAL_10}>
                {loadingBig ? <Skeleton width={500} height={200} /> : <ProjectPostsSection titleRef={bigProjectsRef} bigsmall='big' blogPosts={blogPostsBig} fetch={fetchBigBlogPosts} currentPage={currentBigPage} onPageChange={handlePageChangeBig} />}
            </VerticalSectionContainer >
            <VerticalSectionContainer titlePosition='top' eyebrowText={intl.formatMessage({ id: "projectsPage.verticalSectionGalery.eyebrow" })} title={intl.formatMessage({ id: "projectsPage.verticalSectionGalery.title" })} backgroundColor={RENOVATIE_SECONDARY_NEUTRAL_10}>
                <Stack direction='row' justifyContent='space-between' width='100vW'>
                    <IconButton onClick={onLeftNavClick} sx={{ "&:hover": { backgroundColor: "transparent" } }} >
                        <ArrowBackIosOutlined />
                    </IconButton>
                    {loading ? <Skeleton width={500} /> : <ImageGallery renderPlayPauseButton={(onClick, isPlaying) => <></>} renderItem={renderItem} items={galleryImages} thumbnailPosition='right' autoPlay={false}  {...(!isFullscreen ? {
                        renderRightNav: (onClick, disabled) => {
                            setOnRightNavClick(() => onClick);
                            return <></>
                        },
                        renderLeftNav: (onClick, disabled) => {
                            setOnLeftNavClick(() => onClick);
                            return <></>
                        }
                    } : {})}
                        onScreenChange={(fullscreen) => {
                            setIsFullscreen(fullscreen)
                        }} />}
                    <IconButton onClick={onRightNavClick} sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                        <ArrowForwardIosOutlined />
                    </IconButton>
                </Stack>
            </VerticalSectionContainer>

        </>
    )
}

export default ProjectsPage