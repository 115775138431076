import React from 'react'

const { BLOCKS } = require('@contentful/rich-text-types')
const {
  documentToReactComponents
} = require('@contentful/rich-text-react-renderer')

const fun = (body) => {
  const richTextOptions = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // eslint-disable-next-line react/prop-types
        const img = body.links.assets.block.find(
          (i) => i.sys.id === node.data.target.sys.id
        )
        return <img src={img?.url} alt={img?.title} />
      },
    },
  }
  return documentToReactComponents(body, richTextOptions)
}

export default fun