import { Box } from "@mui/material";
import { ASSETS } from "../../../app/constants";
import { useIntl } from "react-intl";
import CarouselServiceCard from "./CarouselServiceCard";
import ScrollCarousel from "./ScrollCarousel";
import { selectServices } from "../../../store/slices/contentfulSlice";
import { useAppSelector } from "../../../store/configureStore";


interface HorizontalScrollProps {
    handleOpen: any;
}


const HorizontalScroll = ({ handleOpen }: HorizontalScrollProps) => {

    const intl = useIntl()
    const services = useAppSelector(selectServices);
    const servicesData = services;
    return (
        <Box width={"100%"}>
            <ScrollCarousel>
                {servicesData?.map((service, index) => (
                    <CarouselServiceCard index={index} onReadMore={handleOpen} reverse={index % 2 === 0} image={service.image} title={service.title} description={service.text} />
                ))}
            </ScrollCarousel>
        </Box >
    );
}

export default HorizontalScroll;