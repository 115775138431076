import HeroSection from '../components/homePage/HeroSection'
import { useEffect, useState } from 'react'
import VerticalSectionContainer from '../components/common/VerticalSectionContainer'
import { RENOVATIE_SECONDARY_NEUTRAL_00 } from '../styles/theme'
import { Box } from '@mui/material'
import ServiceCard from '../components/homePage/carousel/ServiceCard'
import { ASSETS } from '../app/constants'
import ServiceDialog from '../components/servicesPage/ServiceDialog'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/configureStore'
import { selectCurrentLanguage } from '../store/slices/uiSlice'
import { loadServices, selectServices } from '../store/slices/contentfulSlice'
import { cleanToTechName } from '../components/layout/header/ServicesMenu'

const ServicesPage = () => {
    const [isOpenServiceDialog, setOpenServiceDialog] = useState<boolean>(false)
    const [currentServiceIndex, setCurrentServiceIndex] = useState<number | null>(null)
    const intl = useIntl();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const services = useAppSelector(selectServices);
    const locale = useAppSelector(selectCurrentLanguage);
    const [galleryImages, setGalleryImages] = useState<any[]>([]);

    useEffect(() => {
        dispatch(loadServices(locale))
    }, [locale])

    const servicesData = services ?? [];

    useEffect(() => {
        if (currentServiceIndex != null) {
            const newGalleryImages = servicesData?.[currentServiceIndex]?.imageGalleryCollection?.items?.map((img: any) => ({
                thumbnail: img.url.includes('videos.ctfassets') ? 'https://img.icons8.com/dusk/64/video.png' : img.url,
                original: img.url,
                ...(img.url.includes('videos.ctfassets') && { video: img.url }) //TODO remove workaround (change ctf model)

            }));
            setGalleryImages(newGalleryImages)
        }
    }, [currentServiceIndex])

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash + 'Hash')
            if (element) {
                const topPos = element.getBoundingClientRect().top + window.pageYOffset
                window.scrollTo({
                    top: topPos,
                    behavior: 'smooth'
                })
            }
        }
    }, [location.hash])


    const handleOpenServiceDialog = (index: number) => {
        setCurrentServiceIndex(index);
        setOpenServiceDialog(true);
    }

    const handleCloseServiceDialog = () => {
        setOpenServiceDialog(false)
        setTimeout(function () {
            setCurrentServiceIndex(null);
        }, 500);
    }


    return (
        <>
            <Helmet>
                <title>DVM - Services</title>
                <meta name="description" content="Check out all the services we offer, whatever you need, we can provide! Bekijk alle diensten die wij aanbieden, wat u ook nodig heeft, wij kunnen het leveren!" />
                <meta name='keywords' content="Services, Renovation, Floor, Kitchen, Painting, Diensten, Renovatie, Vloer, Keuken, Schilderen"></meta>
            </Helmet>
            <ServiceDialog images={galleryImages} cardTitle={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.cardTitle : 'Placeholder text'} title4={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.title4 : 'Placeholder text'} text4={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.text4 : 'Placeholder text'} image4={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.image4 : 'Placeholder text'} image3={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.image3 : 'Placeholder text'} image2={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.image2 : 'Placeholder text'} title3={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.title3 : 'Placeholder text'} title2={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.title2 : 'Placeholder text'} text3={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.text3 : 'Placeholder text'} text2={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.text2 : 'Placeholder text'} open={isOpenServiceDialog} image={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.image : ASSETS.HERO_IMAGE} title={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.title : 'Placeholder title'} text={(currentServiceIndex || currentServiceIndex === 0) ? servicesData?.[currentServiceIndex]?.text : 'Placeholder text'} onClose={handleCloseServiceDialog} />
            <HeroSection title={intl.formatMessage({ id: 'servicesPage.heroSection.title' })} subtitle={intl.formatMessage({ id: 'servicesPage.heroSection.subtitle' })} showContactInfo={true} />
            <VerticalSectionContainer titlePosition='top' eyebrowText={intl.formatMessage({ id: 'servicesPage.verticalServicesContainer.eyebrow' })} title={intl.formatMessage({ id: 'servicesPage.verticalServicesContainer.title' })} backgroundColor={RENOVATIE_SECONDARY_NEUTRAL_00}>
                <Box justifyContent='flex-start' alignItems='center' display='flex' sx={{ flexDirection: 'column ' }}>
                    {
                        servicesData?.map((service, index) =>
                            <div id={cleanToTechName(service.title)?.toLowerCase() + 'Hash'} key={index} style={{ position: 'sticky', top: 200 + index * 10, marginTop: 60, marginBottom: '2rem', width: '70%' }} >
                                <ServiceCard reverse={index % 2 === 0} image={service.image} title={service.cardTitle} description={service.shortText ? service.shortText : service.text} fullSize={true} onReadMore={() => handleOpenServiceDialog(index)} />
                            </div>
                        )
                    }
                </Box>
            </VerticalSectionContainer>
        </>
    )
}

export default ServicesPage