import { Box, Button, Stack, Typography } from '@mui/material'
import ROUTES from '../../config/routes'
import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

interface CallToRequestQuoteProps {
    color?: 'primary' | 'secondary';
}

const CallToRequestQuote = ({ color = 'secondary' }: CallToRequestQuoteProps) => {
    return (
        <Stack direction='column' alignItems='center' spacing={4}>
            <Typography variant='body1' color={color} textAlign='center'>
                <FormattedMessage id="footerCallToAction.text1" />
            </Typography>
            <Typography component='span' color={color} variant='body2' >
                <FormattedMessage id="footerCallToAction.text2" />
            </Typography>
            <Stack direction='column' alignItems='flex-end' spacing={0.5}>

                <Button variant='contained' color={color} disableRipple component={Link} to={ROUTES.CONTACT}>
                    <FormattedMessage id="footerCallToAction.btnText" />

                </Button>
                <Typography color={color} variant='caption'> <FormattedMessage id="footerCallToAction.underButtonText" /></Typography>
            </Stack>
        </Stack >
    )
}

export default CallToRequestQuote