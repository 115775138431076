import { Box, Grid, Stack, Typography } from "@mui/material";
import { RENOVATIE_SECONDARY_NEUTRAL_00 } from "../../styles/theme";
import { ASSETS } from "../../app/constants";
import LicensedSegment from "./LicensedSegment";
import WarrantySegment from "./WarrantySegment";

const BadgesSection = () => {
    const styles = {
        section: {
            minHeight: '30%',
            width: '100%',
            backgroundColor: RENOVATIE_SECONDARY_NEUTRAL_00,
            padding: '8em 0',

        }
    }
    return (
        <section style={styles.section}>
            <Stack alignItems='center' direction='column' width='100%' justifyContent='center'
                spacing={{ xs: 2, md: 12 }} bgcolor={'secondary.main'}>
                <LicensedSegment />
                <WarrantySegment />
            </Stack>
        </section>
    );
}

export default BadgesSection;