import { locale } from './locale';
import { messages, SupportedLocaleKeys } from './messages';

export * from './locale';
export * from './messages';
export * from './translate';

export { locale, messages };

export const getLocalizedMessages = (locale: SupportedLocaleKeys) => {
	return messages[locale];
};
