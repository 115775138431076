import { Grid, Typography, Box, Stack, styled } from "@mui/material";
import { ASSETS } from "../../app/constants";
import { FormattedMessage } from "react-intl";

const WUSSmallScreen = () => {
    const Wrapper = styled('section')({
        margin: 0,
    })

    const styles = {
        backgroundImage: {
            backgroundImage: `url(${ASSETS.HAPPY_PEOPLE})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'bottom',
            width: '100%',
            margin: '0 0 1% auto',
            aspectRatio: '513/337'
        },
    }

    return (
        <Wrapper>
            <Grid container spacing={8} padding={3}>
                <Grid item xs={12}>
                    <Box height='100%'>
                        <Box sx={styles.backgroundImage} />
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ display: 'grid', placeItems: 'center' }}>
                    <Stack>
                        <Typography variant="h5" color="primary" fontWeight='bold'>
                            <FormattedMessage id='aboutUsPage.eyebrowText1' />
                        </Typography>
                        <Typography variant="h3" color="initial" lineHeight='8rem'>
                            <FormattedMessage id='aboutUsPage.subtitle1' />
                        </Typography>
                        <Typography variant="body1" color="initial">
                            <FormattedMessage id='aboutUsPage.textBlock' />
                            <br />
                            <br />
                            <FormattedMessage id='aboutUsPage.textBlock2' />
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Wrapper>
    );
}

export default WUSSmallScreen;