import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Stack, Typography, Box, IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PinterestIcon from '@mui/icons-material/Pinterest';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';

const FollowUsNavigation = () => {
    const routeTo = [
        {
            to: 'https://www.facebook.com/people/DVM-Renovatie-en-Gips/100063623311372/',
            icon: <FacebookIcon key={'facebook'} fontSize='small' color='secondary' />,
        },
        {
            to: 'https://www.instagram.com/dvm_renovatie?igsh=MWxmdmpiMXdtcTc1dA==',
            icon: <InstagramIcon key={'pinterest'} fontSize='small' color='secondary' />,

        },
        {
            to: 'https://pin.it/1raPw5t',
            icon: <PinterestIcon key={'pinterest'} fontSize='small' color='secondary' />,
        },
        {
            to: 'https://www.linkedin.com/in/dvm-renovatie-en-gips-5a4342263/',
            icon: <LinkedInIcon key={'linkedin'} fontSize='small' color='secondary' />,
        },

    ]
    return (
        <>
            <Stack direction='column' alignItems='center'>
                <Typography pb={2} color='secondary' fontSize='1.25rem' lineHeight='1.25rem' fontWeight={700}>
                    <FormattedMessage id='footer.followUs' />
                </Typography>
                <Box gap={1} display='flex'>
                    {routeTo?.map(({ icon, to }, i) => (
                        <Link to={to} target="_blank" rel="noopener noreferrer">
                            <IconButton >
                                {icon}
                            </IconButton>
                        </Link>
                    ))}
                </Box>
            </Stack>
        </>
    )
}

export default FollowUsNavigation;