import React from 'react'
import { Box, Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import { ASSETS } from '../../app/constants';
import ContactOptions from '../common/ContactOptions';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { motion, useScroll, useTransform } from 'framer-motion';

interface HeroSectionProps {
    title: string;
    subtitle: string;
    showContactInfo: boolean;
}

const HeroSection = ({ title, subtitle, showContactInfo }: HeroSectionProps) => {
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { scrollYProgress } = useScroll()
    const y = useTransform(scrollYProgress, [0, 1], ["-10%", "180%"])
    const location = useLocation();
    const { pathname } = location;
    const intl = useIntl();
    //                                                              cs    cl                     ncs  ncl
    return (
        <Box width="100%" height={showContactInfo ? (smallScreen ? 1300 : 650) : (smallScreen ? 900 : 750)} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
        }}>
            <Box sx={{
                position: 'absolute',
                zIndex: '0',
                width: '100%', height: showContactInfo ? (smallScreen ? 1300 : 650) : (smallScreen ? 900 : 750),
                overflow: 'hidden'
            }}>
                <motion.div style={{
                    width: '100%', height: '100%',
                    backgroundImage: `url(${ASSETS.HERO_IMAGE})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'bottom',
                    y
                }} />
            </Box>
            <Stack direction='column' justifyContent='center' spacing={12} pt={5} zIndex={1}>
                <Stack direction='column' justifyContent='center' spacing={4} p={0}>
                    <motion.div
                        initial={{ x: 1000, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1, delay: 0.5 }}
                    >
                        <Box display='flex' justifyContent='center'>
                            <Typography variant='h1' color='secondary' textAlign='center' maxWidth='90vW'>{title}</Typography>

                        </Box>
                    </motion.div>

                    {
                        (pathname !== '/home') &&
                        (
                            <motion.div
                                initial={{ x: 1000, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 1, delay: 0.5 }}
                            >
                                <Box display='flex' justifyContent='center'>
                                    <Typography variant='h4' color='secondary' textAlign='center' maxWidth='90vW'>{subtitle}</Typography>

                                </Box>
                            </motion.div>
                        )
                    }
                    {
                        (pathname === '/home') &&
                        (
                            <motion.div
                                initial={{ x: 1000, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 1, delay: 0.5 }}
                                style={{ textAlign: 'center' }}
                            >
                                <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
                                    <Typography
                                        color="secondary"
                                        maxWidth='90vW'
                                        sx={{ fontWeight: "bold", paddingBottom: "1rem", fontSize: '2.5rem' }}
                                    >
                                        {intl.formatMessage({ id: "homePage.heroSection.checklistTitle" })}
                                    </Typography>
                                    <Box>
                                        <Typography sx={{ fontSize: '1rem', lineHeight: '1.5rem' }} color="secondary">
                                            {intl.formatMessage({ id: "homePage.heroSection.checklist1" })}
                                        </Typography>
                                        <Typography sx={{ fontSize: '1rem', lineHeight: '1.5rem', marginTop: '1rem' }} color="secondary">
                                            {intl.formatMessage({ id: "homePage.heroSection.checklist2" })}
                                        </Typography>
                                        <Typography sx={{ fontSize: '1rem', lineHeight: '1.5rem', marginTop: '1rem' }} color="secondary">
                                            {intl.formatMessage({ id: "homePage.heroSection.checklist3" })}
                                        </Typography>
                                        <Typography sx={{ fontSize: '1rem', lineHeight: '1.5rem', marginTop: '1rem' }} color="secondary">
                                            {intl.formatMessage({ id: "homePage.heroSection.checklist4" })}
                                        </Typography>
                                        <Typography sx={{ fontSize: '1rem', lineHeight: '1.5rem', marginTop: '1rem' }} color="secondary">
                                            {intl.formatMessage({ id: "homePage.heroSection.checklist5" })}
                                        </Typography>
                                        <Typography sx={{ fontSize: '1rem', lineHeight: '1.5rem', marginTop: '1rem' }} color="secondary">
                                            {intl.formatMessage({ id: "homePage.heroSection.checklist6" })}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </motion.div >
                        )
                    }
                    <Box display='flex' justifyContent='center'>
                        <motion.div
                            initial={{ x: 1000, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ duration: 1, delay: 1.5 }}
                        >
                            <Link to='/contact'>
                                <Button variant='contained' color='secondary' >
                                    <FormattedMessage id="common.requestAQuote" />
                                </Button>
                            </Link>
                        </motion.div>
                    </Box>
                </Stack >
                {
                    <Box>
                        {showContactInfo && <motion.div
                            initial={{ x: 1000, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ duration: 1, delay: 2 }}
                        >
                            <ContactOptions direction='row' spacing={largeScreen ? 10 : 2} />
                        </motion.div >}
                    </Box >
                }
            </Stack >
        </Box >
    )
}

export default HeroSection