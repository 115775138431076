import uiReducer from './slices/uiSlice';
import contentfulReducer from './slices/contentfulSlice';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    ui: uiReducer,
    contentful: contentfulReducer
});

export type RootReducer = ReturnType<typeof rootReducer>;

export default rootReducer;
