import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Grow, IconButton, Paper, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ROUTES from '../../config/routes';
import { RENOVATIE_PRIMARY_BLUE } from '../../styles/theme';
import { CallToAction } from '@mui/icons-material';
import CallToRequestQuote from '../common/CallToRequestQuote';
import ImageGallery from 'react-image-gallery';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';
interface ServiceDialogProps {
    cardTitle: string;
    image: string;
    title: string;
    text: string;
    name?: string;
    image2: string;
    title2: string;
    text2: string;
    image3: string;
    title3: string;
    text3: string;
    image4: string;
    title4: string;
    text4: string;
    images: any[];
    open: boolean;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Grow ref={ref} {...props} />;
});

const ServiceDialog = ({ image, name, cardTitle, images, title, text, title2, text2, title3, image2, image3, text3, title4, image4, text4, open, onClose }: ServiceDialogProps) => {
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const smallScreen = !useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const [onLeftNavClick, setOnLeftNavClick] = useState<any>(null);
    const [onRightNavClick, setOnRightNavClick] = useState<any>(null);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const renderItem = (item: any) => {
        if (item.video) {
            return (
                <div className="image-gallery-image" >
                    <video controls style={{ maxHeight: '600px', width: '100%' }}>
                        <source src={item.video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        }

        return (
            <div className="image-gallery-image">
                <img src={item.original} alt="" style={{ maxWidth: '100%', maxHeight: '600px' }} />
            </div>
        );
    };

    return (
        <Dialog fullScreen={!largeScreen} open={open} onClose={onClose} scroll='body' fullWidth maxWidth='md' TransitionComponent={Transition} transitionDuration={800}>
            <DialogTitle>
                <Stack direction='row' justifyContent='space-between' width='100%'>
                    <IconButton onClick={() => { }} disabled >
                        <CloseIcon sx={{ color: 'white' }} />
                    </IconButton>
                    <Stack direction='column' justifyContent='flex-start' alignItems='center'>
                        <div style={{
                            backgroundImage: 'linear-gradient(to right, black 50%, blue 50%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}>

                            <Typography variant='h2' textAlign='center'>{cardTitle}</Typography>
                        </div>
                        <Divider sx={{ width: '70%', height: 10, borderBottomWidth: 4, borderColor: RENOVATIE_PRIMARY_BLUE }} variant='middle' />
                    </Stack>
                    <IconButton onClick={onClose} disableTouchRipple disableFocusRipple disableRipple>
                        <CloseIcon color='primary' />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack justifyContent='flex-start' alignItems='center' spacing={10} flexShrink={1}>
                    <Stack padding={5} width='80%' direction='column' spacing={2} alignItems='center' justifyContent='flex-start' style={{ borderRadius: 50, background: 'linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, #1507AB 20%, #1507AB 100%)' }} >
                        <img src={image} width='400px' height='300px' />
                        <Typography variant='h3' color='secondary' textAlign='center'>{title}</Typography>
                        <Typography textAlign='center' variant='body1' color='secondary'>{text}</Typography>
                    </Stack>
                    {!smallScreen ?
                        <Stack padding={5} width='100%' direction='row' spacing={2} alignItems='center' justifyContent='flex-start' style={{ borderRadius: 50, background: 'linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, #000000 20%, #000000 100%)' }} >
                            <img src={image2} width='400px' height='300px' />
                            <Stack direction='column' alignItems='center' justifyContent='flex-start' spacing={4}>
                                <Typography variant='h3' color='secondary'>{title2}</Typography>
                                <Typography textAlign='center' variant='body1' color='secondary'>{text2}</Typography>
                            </Stack>
                        </Stack>
                        :
                        <Stack padding={5} width='80%' direction='column-reverse' spacing={2} alignItems='center' justifyContent='flex-start' style={{ borderRadius: 50, background: 'linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, #000000 20%, #000000 100%)' }} >
                            <img src={image2} width='400px' height='300px' />

                            <Stack direction='column' alignItems='center' justifyContent='flex-start' spacing={4}>
                                <Typography variant='h3' color='secondary'>{title2}</Typography>
                                <Typography textAlign='center' variant='body1' color='secondary'>{text2}</Typography>
                            </Stack>
                        </Stack>
                    }
                    {(image3 && text3 && title3) && !smallScreen ?
                        <Stack padding={5} width='100%' direction='row-reverse' spacing={2} alignItems='center' justifyContent='flex-start' style={{ borderRadius: 50, background: 'linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, #1507AB 20%, #1507AB 100%)' }} >
                            <img src={image3} width='400px' height='300px' />
                            <Stack direction='column' alignItems='center' justifyContent='flex-start' spacing={4}>
                                <Typography variant='h3' color='secondary'>{title3}</Typography>
                                <Typography textAlign='center' variant='body1' color='secondary'>{text3}</Typography>
                            </Stack>
                        </Stack>
                        :
                        <Stack padding={5} width='80%' direction='column-reverse' spacing={2} alignItems='center' justifyContent='flex-start' style={{ borderRadius: 50, background: 'linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, #1507AB 20%, #1507AB 100%)' }} >
                            <img src={image3} width='400px' height='300px' />

                            <Stack direction='column' alignItems='center' justifyContent='flex-start' spacing={4}>
                                <Typography variant='h3' color='secondary'>{title3}</Typography>
                                <Typography textAlign='center' variant='body1' color='secondary'>{text3}</Typography>
                            </Stack>
                        </Stack>
                    }
                    {(text4 && title4 && image4) && (!smallScreen ?
                        <Stack padding={5} width='100%' direction='row' spacing={2} alignItems='center' justifyContent='flex-start' style={{ borderRadius: 50, background: 'linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, #000000 20%, #000000 100%)' }} >
                            <img src={image4} width='400px' height='300px' />
                            <Stack direction='column' alignItems='center' justifyContent='flex-start' spacing={4}>
                                <Typography variant='h3' color='secondary'>{title4}</Typography>
                                <Typography textAlign='center' variant='body1' color='secondary'>{text4}</Typography>
                            </Stack>
                        </Stack>
                        :
                        <Stack padding={5} width='80%' direction='column-reverse' spacing={2} alignItems='center' justifyContent='flex-start' style={{ borderRadius: 50, background: 'linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, #000000 20%, #000000 100%)' }} >
                            <img src={image4} width='400px' height='300px' />

                            <Stack direction='column' alignItems='center' justifyContent='flex-start' spacing={4}>
                                <Typography variant='h3' color='secondary'>{title4}</Typography>
                                <Typography textAlign='center' variant='body1' color='secondary'>{text4}</Typography>
                            </Stack>
                        </Stack>
                    )}
                    {images && images.length > 0 &&
                        <Stack direction='row' justifyContent='space-between' maxWidth='100%' minWidth='100%' overflow='hidden' flexShrink={1} >
                            <IconButton onClick={onLeftNavClick} sx={{ "&:hover": { backgroundColor: "transparent" } }} >
                                <ArrowBackIosOutlined />
                            </IconButton>
                            <ImageGallery renderItem={renderItem} renderPlayPauseButton={(onClick, isPlaying) => <></>} items={images} thumbnailPosition='right' autoPlay={true}  {...(!isFullscreen ? {
                                renderRightNav: (onClick, disabled) => {
                                    setOnRightNavClick(() => onClick);
                                    return <></>
                                },
                                renderLeftNav: (onClick, disabled) => {
                                    setOnLeftNavClick(() => onClick);
                                    return <></>
                                }
                            } : {})}
                                onScreenChange={(fullscreen) => {
                                    setIsFullscreen(fullscreen)
                                }} />
                            <IconButton onClick={onRightNavClick} sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                                <ArrowForwardIosOutlined />
                            </IconButton>
                        </Stack>
                    }
                    <Stack component={Paper} sx={{ backgroundColor: RENOVATIE_PRIMARY_BLUE }} padding={5}>
                        <CallToRequestQuote color='secondary' />
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog >
    )
}

export default ServiceDialog