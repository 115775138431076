import { Button, Stack, Typography } from '@mui/material'
import RnvTextField from '../../../components/common/RnvTextField';
import { Checkboxes } from 'mui-rff';
import { Form } from 'react-final-form';
import RnvSelectField from '../../../components/common/RnvSelectField';
import emailjs from '@emailjs/browser';
import { ENV_VARS } from "../../../config/environmentVariables";
import REGEX from '../../../app/regex';
import { ASSETS } from '../../../app/constants';
import { useIntl } from 'react-intl';
const emailRegex = new RegExp(REGEX.EMAIL)
const phoneNumberRegex = new RegExp(REGEX.PHONE_NUMBER)
interface LetsTalkFormValues {
    fullName: string;
    phoneNumber: string;
    email: string;
    city: string;
    area: string;
    services: any[];
    moreInformation: string;
    privacyPolicy: boolean;
}

const LetsTalkForm = () => {
    const intl = useIntl();

    const handleSubmit = async (values: LetsTalkFormValues, form: any) => {
        emailjs.sendForm(
            ENV_VARS.EMAILJS_SERVICE_KEY, ENV_VARS.EMAILJS_LETS_TALK_TEMPLATE_ID,
            'form'
            , ENV_VARS.EMAILJS_PUBLIC_KEY
        )
        form.restart();
    }

    const handleValidate = async (values: LetsTalkFormValues) => {
        let returnObject = {} as any
        if (!values.fullName) {
            returnObject.fullName = 'This field is required!'
        }
        if (!phoneNumberRegex.test(values.phoneNumber)) {
            returnObject.phoneNumber = 'That is not a valid phone number!'
        }
        if (!emailRegex.test(values.email)) {
            returnObject.email = 'That is not a valid email address'
        }
        if (!values.city) {
            returnObject.city = 'This field is required!'
        }
        if (!values.services) {
            returnObject.services = 'This field is required!'
        }
        if (!values.moreInformation) {
            returnObject.moreInformation = 'This field is required!'
        }
        if (!values.privacyPolicy) {
            returnObject.privacyPolicy = 'You have to agree to our privacy policy!'
        }

        return returnObject
    }

    return (
        <Form
            onSubmit={handleSubmit}
            validate={handleValidate}
            render={({ handleSubmit }) => (
                <form id={'form'} onSubmit={handleSubmit} noValidate={true}>
                    <Stack direction='column' px={6} py={5} spacing={1.5} justifyContent='center' alignItems='center'>
                        <RnvTextField name='fullName' label={intl.formatMessage({ id: 'letsTalkForm.fullName.label' })} placeholder={intl.formatMessage({ id: 'letsTalkForm.fullName.placeholder' })} required={true} />
                        <RnvTextField name='phoneNumber' label={intl.formatMessage({ id: 'letsTalkForm.phoneNumber.label' })} placeholder={intl.formatMessage({ id: 'letsTalkForm.phoneNumber.placeholder' })} required={true} />
                        <RnvTextField name='email' label={intl.formatMessage({ id: 'letsTalkForm.email.label' })} placeholder={intl.formatMessage({ id: 'letsTalkForm.email.placeholder' })} required={true} />
                        <RnvTextField name='city' label={intl.formatMessage({ id: 'letsTalkForm.city.label' })} placeholder={intl.formatMessage({ id: 'letsTalkForm.city.placeholder' })} required={true} />
                        <RnvSelectField multiSelect name='services' label={intl.formatMessage({ id: 'letsTalkForm.services.title' })} placeholder='awd' required={true}
                            data={[
                                { value: 'Renovation', label: intl.formatMessage({ id: 'letsTalkForm.services.items.renovation' }) },
                                { value: 'Plaster Work', label: intl.formatMessage({ id: 'letsTalkForm.services.items.plaster_work' }) },
                                { value: 'Custom Kitchen Molds', label: intl.formatMessage({ id: 'letsTalkForm.services.items.custom_kitchen_molds' }) },
                                { value: 'Interior and Exterior Decoration', label: intl.formatMessage({ id: 'letsTalkForm.services.items.iae_decor' }) },
                                { value: 'Modern Drywall and ceilings', label: intl.formatMessage({ id: 'letsTalkForm.services.items.drywall' }) },
                                { value: 'Floors laminate and carpets', label: intl.formatMessage({ id: 'letsTalkForm.services.items.floors' }) },
                                { value: 'Painting work', label: intl.formatMessage({ id: 'letsTalkForm.services.items.painting' }) },
                                { value: 'Innovative Advices', label: intl.formatMessage({ id: 'letsTalkForm.services.items.advice' }) },
                            ]} />
                        <RnvTextField name='moreInformation' label={intl.formatMessage({ id: 'letsTalkForm.moreInformation' })} placeholder='john.smith@domain.com' multiline={true} rows={4} />
                        <Checkboxes
                            name="privacyPolicy"
                            size="small"
                            style={{
                                fontSize: '0.8rem'
                            }}
                            data={{
                                value: false,
                                label: <Typography variant='caption' color='inherit'>{intl.formatMessage({ id: 'letsTalkForm.pp_intro' })} <Typography component='a' href={ASSETS.PRIVACY_POLICY} target="_blank" sx={{ textDecoration: 'none' }} variant='caption' color='primary'>{intl.formatMessage({ id: 'letsTalkForm.pp_label' })}</Typography></Typography>,
                            }}
                        />
                        <br />
                        <Button type='submit' variant='contained' sx={{ maxWidth: '60%' }} >{intl.formatMessage({ id: 'letsTalkForm.btnSubmit' })} </Button>
                    </Stack>
                </form>
            )}
        />
    )
}

export default LetsTalkForm