import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

interface ContactOptionProps {
    icon: JSX.Element;
    title: string;
    subtitle: string;
    width?: 'auto' | '100%';
    justifyContent?: 'center' | 'flex-end' | 'flex-start' | 'space-between' | 'space-around' | 'space-evenly' | 'inherit';
    alignItems?: 'center' | 'flex-end' | 'flex-start' | 'space-between' | 'space-around' | 'space-evenly' | 'inherit';
    anchorTitle?: boolean;
}

const ContactOption = ({ icon, title, subtitle, width = 'auto', justifyContent = 'flex-start', alignItems = 'flex-start', anchorTitle = false }: ContactOptionProps) => {
    return (
        <>
            {icon}
            <Stack direction='column' justifyContent={justifyContent} alignItems={alignItems} spacing={-0.5}>
                {anchorTitle ?
                    <a href={title} style={{ color: 'white' }}>
                        +31 648989364
                    </a>
                    :
                    <Typography color='secondary' fontSize='0.9375rem' lineHeight='1.75rem' fontWeight={700} component='span'> {title}</Typography>}
                <Typography color='secondary' fontSize='0.75rem' lineHeight='1.875rem' fontWeight={500} component='span'> {subtitle}</Typography>
            </Stack>
        </>
    )
}

export default ContactOption