import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { locale } from '../../i18n/locale';

import { SupportedLocaleKeys } from '@rnv/i18n/messages';
import { RootState } from '@rnv/store/configureStore';


interface UiSliceState {
    loading: boolean;
    isDrawerOpen: boolean;
    language: SupportedLocaleKeys;
}

// Initial state for the slice
const initialState: UiSliceState = {
    loading: false,
    isDrawerOpen: false,
    language: locale,
};

// Actual Slice
export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setLanguage: (state: UiSliceState, action: PayloadAction<SupportedLocaleKeys>) => {
            state.language = action.payload;
        },
        toggleDrawer: (state: UiSliceState) => {
            state.isDrawerOpen = !state.isDrawerOpen;
        }
    },
});

export const { setLanguage, toggleDrawer } = uiSlice.actions;

export const selectCurrentLanguage = (state: RootState): SupportedLocaleKeys => state.ui.language;
export const selectIsDrawerOpen = (state: RootState): boolean => state.ui.isDrawerOpen;

export default uiSlice.reducer;
