
import { CssBaseline, ThemeProvider } from '@mui/material';
import { IntlProvider } from 'react-intl';
import { getLocalizedMessages, SupportedLocaleKeys } from './i18n';
import Pages from './pages';
import { useAppSelector } from './store/configureStore';
import { selectCurrentLanguage } from './store/slices/uiSlice';
import theme from './styles/theme';
import "react-image-gallery/styles/css/image-gallery.css";

function App() {
  const locale = useAppSelector(selectCurrentLanguage) as SupportedLocaleKeys;
  const messages = getLocalizedMessages(locale);

  return (
    <IntlProvider locale={locale} messages={messages as any}>
      <ThemeProvider theme={theme} >
        <CssBaseline />
        <Pages />
      </ThemeProvider>
    </IntlProvider >
  );
}

export default App;
