import { Divider, Stack, Theme, useMediaQuery } from '@mui/material'
import ContactOption from './ContactOption'
import PhoneSharpIcon from '@mui/icons-material/PhoneSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import { useIntl } from 'react-intl';

interface ContactOptionsProps {
    direction: "row" | "row-reverse" | "column" | "column-reverse";
    spacing: number;
}

const ContactOptions = ({ direction, spacing }: ContactOptionsProps) => {
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const intl = useIntl();
    return (
        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={spacing} justifyContent='center' alignItems='center'>
            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={4}>
                <ContactOption icon={<PhoneSharpIcon sx={{ border: "solid 2px", borderRadius: 10, height: 45, width: 45, padding: 1.3 }} color='secondary' />} title={'tel:+31 648989364'} anchorTitle={true} subtitle='08:00 am - 07:00 pm' />
            </Stack>
            <Divider sx={{ width: { xs: '50%', lg: '1px' }, height: { xs: '1px', lg: '200%' } }} color='white' orientation={largeScreen ? 'vertical' : 'horizontal'} />
            <Stack sx={{ textDecoration: 'none' }} component={'a'} href='mailto:info@dvmrenovatie.com' direction='row' justifyContent='flex-start' alignItems='center' spacing={4}>
                <ContactOption icon={<EmailSharpIcon sx={{ border: "solid 2px", borderRadius: 10, height: 45, width: 45, padding: 1.3 }} color='secondary' />} title={'info@dvmrenovatie.com'} subtitle={intl.formatMessage({ id: 'homePage.heroSection.write' })} />
            </Stack>
            <Divider sx={{ width: { xs: '50%', lg: '1px' }, height: { xs: '1px', lg: '200%' } }} color='white' orientation={largeScreen ? 'vertical' : 'horizontal'} />
            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={4}>
                <ContactOption icon={<LocationOnSharpIcon sx={{ border: "solid 2px", borderRadius: 10, height: 45, width: 45, padding: 1.3 }} color='secondary' />} title={'Rotterdam'} subtitle={intl.formatMessage({ id: 'homePage.heroSection.visit' })} />
            </Stack>
        </Stack>
    )
}

export default ContactOptions