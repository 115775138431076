import { Stack } from "@mui/system";
import Typography from '@mui/material/Typography'
import ContactOption from "../common/ContactOption";
import PhoneSharpIcon from '@mui/icons-material/PhoneSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import { useIntl } from "react-intl";


const ContactCard = () => {
    const intl = useIntl();
    const styles = {
        container: {
            padding: '2.3rem',
            height: 'fit-content'
        },
    }

    return (
        <Stack justifyContent='flex-start' alignItems='center' spacing={4} bgcolor='primary.main' sx={styles.container}>
            <Typography marginTop={4} marginBottom={4} variant="h3" color="secondary">{intl.formatMessage({ id: "contactPage.contactOptions.title" })}</Typography>
            <Stack direction='column' justifyContent='center' alignItems='center' spacing={2} width='100%'>
                <ContactOption icon={<PhoneSharpIcon sx={{ border: "solid 2px", borderRadius: 10, height: 45, width: 45, padding: 1.3 }} color='secondary' />} title={'+31 648989364'} subtitle='08:00 am - 07:00 pm' justifyContent="inherit" alignItems="inherit" />
            </Stack>
            <Stack direction='column' justifyContent='center' alignItems='center' spacing={2} width='100%'>
                <ContactOption icon={<EmailSharpIcon sx={{ border: "solid 2px", borderRadius: 10, height: 45, width: 45, padding: 1.3 }} color='secondary' />} title={'info@dvmrenovatie.com'} subtitle={intl.formatMessage({ id: "contactPage.contactOptions.write" })} justifyContent="inherit" alignItems="inherit" />
            </Stack>
            <Stack direction='column' justifyContent='center' alignItems='center' spacing={2} width='100%'>
                <ContactOption icon={<LocationOnSharpIcon sx={{ border: "solid 2px", borderRadius: 10, height: 45, width: 45, padding: 1.3 }} color='secondary' />} title={'Rotterdam'} subtitle={intl.formatMessage({ id: "contactPage.contactOptions.visit" })} justifyContent="inherit" alignItems="inherit" />
            </Stack>
        </Stack>
    );
}

export default ContactCard;