import { useMediaQuery, Theme } from "@mui/material";
import AboutUsLargeScreen from "./AboutUsLargeScreen";
import AboutUsSmallScreen from "./AboutUsSmallScreen";

const WhyUsSection = () => {
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <>
            {
                largeScreen ?
                    <AboutUsLargeScreen /> : <AboutUsSmallScreen />
            }
        </>
    );
}

export default WhyUsSection;